import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = ({ ...props }) => (
  <ContentLoader
    speed={2}
    width={30}
    height={30}
    viewBox="0 0 30 30"
    backgroundColor="#101010"
    foregroundColor="#333333"
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="30" height="30" />
  </ContentLoader>
);

export default Skeleton;
