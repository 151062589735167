import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";
import { getProduct } from "../../../../../redux/slises/Ecwid/ecwid";

import EcwidProduct from "./Product/OrderProductData";

import styles from "./OrderItem.module.sass";

function OrderBlock({
  orderID,
  total,
  date,
  count,
  products,
  selectedPeriod,
  selectedArtist,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    for (const product of products) {
      dispatch(getProduct(product.productId));
    }
  }, [products]);

  return (
    <div className={styles.orderBlock}>
      <div className={styles.orderBlockInfo}>
        <div className={styles.orderBlockTitle}>
          <div className={styles.orderBlockSum}>
            {total > 0 ? (
              <span>{total + " ₽"}</span>
            ) : (
              <span>{total + " ₽"}</span>
            )}
          </div>
          <div className={styles.orderBlockOther}>
            {orderID.startsWith("Vozv") ? (
              <>
                <span className={styles.orderBlockNumb}>
                  {t("orderListTitleReturn")}
                </span>
                <span className={styles.orderBlockNumb}>
                  #{orderID.substring(5)}
                </span>
              </>
            ) : orderID.startsWith("Rozn") ? (
              <span className={styles.orderBlockNumb}>
                {t("orderListTitleRetail")}
              </span>
            ) : (
              <span className={styles.orderBlockNumb}>#{orderID}</span>
            )}
            <span className={styles.orderBlockDate}>
              {moment(date).format("DD.MM.YYYY")}
            </span>
            {count > 0 ? (
              <span className={styles.orderBlockCount}>
                {count} <span>{t("orderListTitlePiece")}</span>
              </span>
            ) : (
              <span className={styles.orderBlockCount}>
                {Math.abs(count)} <span>{t("orderListTitlePiece")}</span>
              </span>
            )}
          </div>
        </div>
        <div className={styles.orderBlockList}>
          {products.map((product, index) => (
            <div className={styles.orderItem} key={index}>
              <div className={styles.orderItemImg} title={product.name}>
                <EcwidProduct
                  productId={product.productId}
                  productName={product.name}
                  title={product.name}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.orderItemList}>
        {products.map((product, index) => (
          <div className={styles.orderItem} key={index}>
            <p className="caption-3" key={index}>
              {product.name}
            </p>
            {product.variant && product.variant.length > 0 && (
              <div className={styles.orderItemSize}>
                <span className="caption-4">{product.variant}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderBlock;
