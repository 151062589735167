export function calculatedYearTotal(orders) {
  const yearTotal = Object.values(orders).reduce((acc, order) => {
    const year = order.date.split("-")[0];
    if (!acc[year]) {
      acc[year] = {
        year: 0,
        total: 0,
      };
    }
    acc[year].year = year;
    acc[year].total += order.total;
    return acc;
  }, {});

  return yearTotal;
}
