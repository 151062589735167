import React from "react";
import styles from "./Main.module.sass";
import { motion } from "framer-motion";

function Main() {
  const cardVariants = {
    offscreen: {
      y: 50,
      opacity: 0,
    },
    onscreen: (customDelay) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: customDelay,
        type: "spring",
        bounce: 0.2,
        duration: 1,
      },
    }),
  };

  const contentVariants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: (customDelay) => ({
      y: 0,
      rotate: 0,
      opacity: 1,
      transition: {
        delay: customDelay,
        type: "spring",
        bounce: 0.2,
        duration: 1,
      },
    }),
  };

  return (
    <motion.div className={styles.main} initial="offscreen" animate="onscreen">
      <div className={styles.wrapper}>
        <div className={styles.block}>
          <motion.div
            className={styles.title}
            variants={contentVariants}
            custom={0.8}
          >
            <h1>
              Личный кабинет артистов <br />и партнеров VSRAP
            </h1>
          </motion.div>
          <motion.div
            className={styles.action}
            variants={contentVariants}
            custom={0.85}
          >
            <a href="/account/login" className={styles.button}>
              Войти в кабинет
            </a>
          </motion.div>
          <div className={styles.mainBox}>
            <div className={styles.box}>
              <div className={`${styles.circles} ${styles.gradient}`}>
                <div key="0"></div>
                <div key="1"></div>
                <div key="2"></div>
                <div key="3"></div>
                <div key="4"></div>
                <div key="5"></div>
                <div key="6"></div>
                <div key="7"></div>
              </div>
              <div className={styles.circles}>
                <div key="0"></div>
                <div key="1"></div>
                <div key="2"></div>
                <div key="3"></div>
                <div key="4"></div>
                <div key="5"></div>
                <div key="6"></div>
                <div key="7"></div>
              </div>
            </div>
          </div>
          <motion.div
            className={styles.preview}
            initial="offscreen"
            animate="onscreen"
            variants={cardVariants}
            custom={0.9}
          >
            <div className={styles.previewWrapper}>
              <img
                alt="Превью платформы vsrapboy"
                decoding="async"
                fetchpriority="high"
                src="/img/homepage/preview.svg"
                srcSet="/img/homepage/preview.svg 1x, /img/homepage/preview.svg 2x"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default Main;
