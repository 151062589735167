import React from "react";
import Header from "../../components/HomePage/Header/Header";
import Main from "../../components/HomePage/Main/Main";
import Cards from "../../components/HomePage/Cards/Cards";
import Footer from "../../components/HomePage/Footer/Footer";

import styles from "../../components/HomePage/Homepage.module.sass";

function Homepage() {
  return (
    <div className={styles.homepage}>
      <div className={styles.container}>
        <Header />
        <Main />
        <Cards />
        <Footer />
      </div>
    </div>
  );
}

export default Homepage;
