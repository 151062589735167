import React from "react";
import moment from "moment";
import "moment/locale/ru";
import { useTranslation } from "react-i18next";
import Select from "react-select";

function SelectLang() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("selectedLanguage") || "ru"
  );

  React.useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    moment.locale(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage, i18n]);

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
  };

  const languages = [
    { value: "ru", label: "Русский" },
    { value: "en", label: "English" },
  ];

  return (
    <Select
      options={languages} // Передаем доступные языки
      value={languages.find((lang) => lang.value === selectedLanguage)} // Устанавливаем текущее значение
      onChange={(selectedOption) => changeLanguage(selectedOption.value)} // Обработчик изменения
      isSearchable={false} // Отключаем поиск
      className="select" // CSS класс для стилизации
      classNamePrefix="select" // Префикс для стилей
      onFocus={(event) => {
        // Отключаем автозаполнение и подобные функции браузера
        event.target.setAttribute("autocomplete", "off");
        event.target.setAttribute("autocorrect", "off");
        event.target.setAttribute("autocapitalize", "none");
      }}
    />
  );
}

export default SelectLang;
