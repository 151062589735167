import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../axios";

export const updateUsers = createAsyncThunk(
  "user/updateUser",
  async ({ userId, updatedData }) => {
    const { data } = await axios.put(`/admin/user/${userId}/edit`, {
      userId,
      updatedData,
    });
    return data;
  }
);

const initialState = {
  statusUpdateUser: "",
};

const usersSlice = createSlice({
  name: "updateAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUsers.pending, (state) => {
        state.statusUpdateUser = "loading";
      })
      .addCase(updateUsers.fulfilled, (state, action) => {
        state.statusUpdateUser = "saving";
      })
      .addCase(updateUsers.rejected, (state) => {
        state.statusUpdateUser = "error";
      });
  },
});

export const adminReducer = usersSlice.reducer;
