import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { clearProduct } from "../../../../redux/slises/Ecwid/ecwid";
import Skeleton from "./Skeleton";
import TopProductItem from "./Item/TopProductItem";

import styles from "./TopProduct.module.sass";

const TopProduct = ({ selectedPeriod, selectedCollection }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visibleOrders, setVisibleOrders] = React.useState(15);

  React.useEffect(() => {
    setVisibleOrders(15);
    dispatch(clearProduct());
  }, [selectedPeriod, dispatch]);

  React.useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.pageYOffset >=
        document.documentElement.scrollHeight
      ) {
        setVisibleOrders((prevVisibleOrders) => prevVisibleOrders + 15);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [selectedPeriod]);

  const productsTop = useSelector((state) => {
    if (state.sales.status === "loading") {
      return [...Array(10)].map((_, index) => <Skeleton key={index} />);
    }

    if (state.sales.status === "loaded") {
      const products = Object.values(
        state.sales.productsTop[selectedPeriod] || {}
      );

      // Сортируем продукты по количеству продаж
      const sortedProducts = products.sort((a, b) => b.count - a.count);

      // Находим максимальное и минимальное количество продаж
      const maxCount = Math.max(...products.map((product) => product.count));
      const minCount = Math.min(...products.map((product) => product.count));

      // Рассчитываем проценты для каждого продукта
      const productsWithPercentages = sortedProducts.map((product) => {
        const percentage =
          ((product.count - minCount) / (maxCount - minCount)) * 100 + 1;
        return {
          ...product,
          percentage: Math.round(percentage),
        };
      });

      if (products.length === 0) {
        return <p className={styles.info}>{t("productListTitleNon")}</p>;
      }

      return (
        <>
          {productsWithPercentages.slice(0, visibleOrders).map((product) => (
            <TopProductItem
              key={product.productId}
              id={product.productId}
              name={product.name}
              total={product.total}
              count={product.count}
              period={selectedPeriod}
              artist={selectedCollection}
              percentage={product.percentage}
            />
          ))}
        </>
      );
    }

    return [];
  });

  return <div className={styles.topProductList}>{productsTop}</div>;
};

export default TopProduct;
