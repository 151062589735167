const enJSON = {
  // title: "VSRAPBOY - Partner's Personal account",
  // title2: "Recent orders",
  // title3: "Editing a profile",
  // title4: "Oh, I think you're lost!",
  // title5: "ONLY FOR",
  // title6: "Personal account of VSRAP partners and artists",
  // title7: "Merchandising",
  // title8: "Distribution",
  // title9: "Integrations",
  // title10: "General settings",
  // title11: "Setting up revenue percentages",
  // title12: "All users",
  // title13: "Profile Editing",
  // title14: "SALE REPORTS",
  // title15: "PRODUCTS",
  // title16: "REVENUE",
  // title17: "PROFIT",
  // title18: "PERIOD",
  // title19: "User registration",
  // title20: "Password recovery",
  // title21: "Account is not activated",
  // title22: "Log in account",
  // title23: "Profit for the specified period",
  // title24: "Profit chart by month",
  // title25: "Revenue for the specified period",
  // title26: "Current year's profit",
  // title27: "Last year's profit",
  // title28: "Number of items sold:",
  // title29: "Merchandising settings",
  // title30: "or",
  //
  // text: "The page is currently unavailable, or you do not have access to this section.",
  // text2: "Setting reports, profit calculation",
  // text3: "Music release statistics",
  // text4: "All advertising analytics",
  // text5:
  //   "Your account has not yet been activated by the administration of the platform.",
  //
  // developer: "Made with love in",
  //
  // btn: "Merchandising",
  // btn2: "Users",
  // btn3: "Settings",
  // btn4: "vsrap.shop",
  // btn5: "Distribution",
  // btn6: "Integrations",
  // btn7: "PageBoy",
  // btn8: "User",
  // btn9: "Log out",
  // btn10: "Go back",
  // btn11: "Profile Settings",
  // btn12: "Save Changes",
  // btn13: "Go to the main page",
  // btn14: "Next",
  // btn15: "All",
  // btn16: "Admins",
  // btn17: "Editors",
  // btn18: "Artists",
  // btn19: "Users",
  // btn20: "Yes",
  // btn21: "No",
  // btn22: "Delete account",
  // btn23: "Login in account",
  // btn24: "Sign up",
  // btn25: "Forgot password",
  // btn26: "Register",
  // btn27: "Recover password",
  // btn28: "Log in using Vkontakte",
  // btn29: "Log in using Google",
  //
  // label: "E-mail:",
  // label2: "ID artist:",
  // label3: "First name:",
  // label4: "Last name:",
  // label5: "Old password:",
  // label6: "New password:",
  // label7: "Standard percentage of revenue:",
  // label8: "Average percentage of revenue:",
  // label9: "High percentage of revenue:",
  // label10: "Standard Revenue threshold:",
  // label11: "High revenue threshold:",
  // label12: "Group:",
  // label13: "Display revenue block in sales reports",
  // label14: "Percentage of revenue:",
  // label15: "ID collection:",
  // label16: "Verified account",
  //
  // placeholder: "For example: info@mail.ru ",
  // placeholder2: "For example: IVANOV",
  // placeholder3: "For example: Ivan",
  // placeholder4: "For example: Ivanov",
  // placeholder5: "",
  // placeholder6: "",
  // placeholder7: "For example: 20",
  // placeholder8: "For example: 22",
  // placeholder9: "For example: 25",
  // placeholder10: "User search",
  // placeholder11: "Your E-mail",
  // placeholder12: "Password",
  // placeholder13: "Name",
  // placeholder14: "Last name",
  // placeholder15: "Repeat password",
  // placeholder16: "For example: 1500000",
  // placeholder17: "For example: 3000000",
  // placeholder18: "Select a collection",
  //
  // role_admin: "Admin",
  // role_editor: "Editor",
  // role_artist: "Artist",
  //
  // info: "Saving...",
  // info2: "Deleting...",
  // info3: "Do you want to delete account?",
  // info4: "Click on the block to see the revenue",
  // info5: "Click on the block to see the profit",
  //
  // error: "Avatar not loaded",
  // error2: "Avatar not deleted",
  // error4: "No changes detected",
  // error5: "An error occurred while saving changes",
  // error6: "No users found for your query",
  // error7: "Failed to get a list of users",
  // error8: "An error occurred while deleting the account",
  // error10: "Error when getting a unique device ID:",
  // error11: "Setting data was not received",
  // error12: "LastOrders for the specified date were not found",
  // error13:
  //   "No login has been made, incorrect account data may have been entered",
  // error14: "Enter a name",
  // error15: "The name is too long",
  // error16: "Enter last name",
  // error17: "Last name is too long",
  // error18: "Enter an email address",
  // error19: "Enter a valid email address",
  // error20: "Come up with a password",
  // error21: "Password length is at least 6 characters",
  // error22: "Re-enter password",
  // error23: "Passwords must match",
  // error24: "The email has already been sent within the last 5 minutes!",
  // error25: "The email is sent once every 5 minutes",
  // error26: "The user with this email was not found",
  //
  // success: "Avatar loaded!",
  // success2: "Avatar deleted!",
  // success4: "Changes saved",
  // success5: "Password reset link successfully sent!",
  // success6: "The password reset link has been sent!",
  //
  // order1: "YEAR",
  // order2: "Refund",
  // order3: "Retail",
  // order4: "pcs",
  //
  // homepage1: "Login in cabinet",
  // homepage2: "Artists personal account and VSRAP partners",
  // homepage3: "Merchandise sales",
  // homepage4: "All sales reports in one place",
  // homepage5: "Distribution statistics",
  // homepage6: "Data from all music platforms",
  // homepage7: "Easier than it seems",
  // homepage8: "How to start using VSRAPBOY",
  // homepage9: "To access the platform you must complete three simple steps",
  // homepage10: "Account registration",
  // homepage11: "Register using social networks or email",
  // homepage12: "Profile verification",
  // homepage13: "Wait for profile confirmation by the platform administration",
  // homepage14: "Select section",
  // homepage15: "Select the section you are interested in and track statistics",
  // homepage16: "Current information",
  // homepage17: "Data updates occur in real time",
  // homepage18:
  //   "Online sales data is received instantly, distribution data is received after each report",
  // homepage19: "Personal account",
  // homepage20: "Mobile and desktop version",
  // homepage21:
  //   "The platform is adaptive to most modern devices and resolutions.",
  // homepage22: "Filter statistics by date of interest",
  // homepage23: "All reports can be filtered by date, year or month",

  //Title page
  titleMain: "VSRAPBOY - Partner's Personal Cabinet",
  titleMainCabinet: "VSRAPBOY",
  titleStatisctiс: "Sales statistics",
  titleAllSettingPlatform: "General site settings",
  titleAllUsers: "All Users",
  titleUserSetting: "User Settings",

  //NotFound page
  text: "The page is currently unavailable or you do not have access to this section.",

  //Roles
  role_admin: "Admin",
  role_editor: "Editor",
  role_artist: "Artist",

  //Sidebar
  sidebarNavTitleSales: "Sales",
  sidebarNavItemStats: "Statistics",
  sidebarNavItemTopSales: "Top Sales",
  sidebarNavItemRecentOrders: "Recent LastOrders",
  sidebarNavItemUsers: "Users",
  sidebarNavItemSettings: "Settings",
  sidebarNavTitlePageBoy: "PageBoy",
  sidebarNavItemAddPage: "Add Page",
  sidebarNavItemPages: "Pages",
  sidebarNavItemPosts: "Posts",
  sidebarAccountBtn: "Sign In",
  sidebarShopBtn: "Vsrap.shop",

  //Header merchandise
  headerTitleStatistic: "Sales",
  headerButtonPageStatistic: "Statistics",
  headerButtonPageTop: "Top Sales",
  headerButtonPageOrders: "Last Orders",

  //Header userlist
  headerTitleUsersList: "Users",
  headerButtonUsersAll: "All",
  headerButtonUsersAdmin: "Admins",
  headerButtonUsersRedactor: "Editors",
  headerButtonUsersArtist: "Artists",
  headerButtonUsersUser: "Users",

  //Header usersetting
  headerTitleUserSetting: "User Settings",
  headerTitleUserSettingMerch: "Sales Settings",
  headerTitleUserSettingUser: "Profile Settings",
  headerTitleUserSettingPlatform: "Site Settings",
  headerTitleUserSettingAuth: "User Authorizations",
  headerButtonUserAccount: "Profile",
  headerButtonUserDevice: "Authorizations",
  buttonUploadAvatar: "Upload Photo",
  buttonDeleteAvatar: "Delete photo",
  infoUploadAvatar:
    "Image in JPG, JPEG or PNG format. Size no larger than 100MB.",
  buttonDeleteUser: "Delete account",
  buttonLogoutUser: "Logout of account",
  buttonSaveSettingUser: "Save",
  labelUserSettingLang: "Site Language",
  labelDescSettingLang:
    "Select the language to be displayed throughout the site",
  labelUserSettingId: "Collection IDs",
  labelDescUserSettingId: "The ecwid collection IDs that apply to this user",
  labelUserSettingPercent: "Percentage of revenue",
  labelDescSettingPercent:
    "If a percentage is specified, only that percentage will be counted",
  labelUserSettingBlock: "Revenue Block",
  labelDescUserSettingBlock:
    "Display the revenue block for the user in sales reports",
  labelUserSettingName: "First and Last Name",
  labelUserSettingActivate: "Activate profile",
  labelDescUserSettingActivate:
    "If disabled, the user will not access the platform",
  labelUserSettingEmail: "Email",
  labelDescUserSettingEmail:
    "This setting can only be changed by the platform administrator",
  labelUserSettingGroup: "Group",
  labelDescUserSettingGroup: "Group to which the user belongs",
  labelUserSettingNewpass: "New password",
  labelDescUserSettingNewpass: "Must be at least 6 characters long",
  labelUserSettingOldpass: "Old password",
  labelDescUserSettingOldpass: "Must be at least 6 characters long",
  placeholderUserNull: "",
  placeholderUserColletion: "Select Collection",
  placeholderUserEmail: "For example: info@mail.ru",
  placeholderUserName: "For example: Ivan",
  placeholderUserSurname: "For example: Ivanov",
  placeholderUserPercent: "For example: 20",
  placeholderUserNewpass: "Enter new password",
  placeholderUserOldpass: "Enter old password",

  //Header platformsetting
  headerTitlePlatformSetting: "Site Settings",
  headerButtonPlatformAll: "General",
  headerButtonPlatformSales: "Sales",
  headerTitlePlatformSettingAll: "General Platform Settings",
  headerTitleUserSettingEcwid: "ECWID Settings",
  headerTitleUserSettingSales: "Setting percent of revenue",
  labelPlatformSettingUrl: "Site Domain",
  labelDescPlatformSettingUrl:
    "Main site domain from which the entire platform will be set from",
  labelPlatformSettingDev: "Development Mode",
  labelDescPlatformSettingDev:
    "Will switch the backend`a address from domain to localhost",
  labelPlatformSettingTitle: "Site Title:",
  labelDescPlatformSettingTitle: "To be used in all page titles",
  labelPlatformSettingDesc: "Description",
  labelDescPlatformSettingDesc: "Short description, max 200 characters",
  labelPlatformSettingCopyright: "Copyrights",
  labelDescPlatformSettingCopyright: "Only used on the homepage for now",
  labelPlatformSettingApi: "API address",
  labelDescPlatformSettingApi: "Address for api connection to ecwid",
  labelPlatformSettingShopId: "Shop ID",
  labelDescPlatformSettingShopId: "Shop ID on ecwid",
  labelPlatformSettingPublicToken: "Public Token",
  labelDescPlatformSettingPublicToken:
    "Public token to connect to ecwid via api",
  labelPlatformSettingSecretToken: "Secret Token",
  labelDescPlatformSettingSecretToken:
    "Secret token for connecting to ecwid over api",
  labelPlatformSettingCollectionId: "Collection ID",
  labelDescPlatformSettingCollectionId:
    "ID of ecwid's most important collection, in which all our other collections are nested",
  labelSalesSettingPercentLow: "Standard Percent",
  labelDescSalesSettingPercentLow:
    "The lowest revenue percentage for calculating artist sales",
  labelSalesSettingPercentMiddle: "Higher percentage",
  labelDescSalesSettingPercentMiddle:
    "Average revenue percentage for calculating artist sales",
  labelSalesSettingPercentHigh: "High percentage",
  labelDescSalesSettingPercentHigh:
    "Maximum revenue percentage for calculating artist sales",
  labelSalesSettingTotalLow: "Standard revenue threshold",
  labelDescSalesSettingTotalLow:
    "Minimum amount to increase percentage for calculating artist sales",
  labelSalesSettingTotalHigh: "High revenue threshold",
  labelDescSalesSettingTotalHigh:
    "Maximum amount for percentage increase when calculating artist sales",
  placeholderPlatformUrl: "For example: vsrapboy.com",
  placeholderPlatformTitle: "For example: Home Page",
  placeholderPlatformDesc: "For example: Best Platform in the Universe",
  placeholderPlatformCopyright: "For example: VSRAP is the coolest",
  placeholderPlatformApi: "For example: ecwid.com",
  placeholderPlatformToken: "For example: asd5asdas5dasdasd2223asd",
  placeholderPlatformPercent: "For example: 20",
  placeholderPlatformPercentMiddle: "For example: 22",
  placeholderPlatformPercentHigh: "For example: 25",
  placeholderPlatformTotalLow: "For example: 1500000",
  placeholderPlatformTotalHigh: "For example: 3000000",

  //Page userlist
  userlistErrorNot: "No users were found for your query",
  userlistErrorUpload: "Failed to retrieve userlist",
  userlistInputPlaceholder: "Searching for a user",

  //Page statistic
  statisticTitleBlockTop: "Top Sales",
  statisticButtonBlockTop: "All Products",
  statisticTitleBlockOrder: "Last Orders",
  statisticButtonBlockOrder: "All Orders",
  statisticTitleBlockYear: "Profit for the selected year",
  statisticTitleBlockPeriodProfit: "Profit for the specified period",
  statisticTitleBlockPeriodRevenue: "Revenue for the specified period",
  statisticPeriodAbbreviatureMonth1: "JNV",
  statisticPeriodAbbreviatureMonth2: "FEV",
  statisticPeriodAbbreviatureMonth3: "MAR",
  statisticPeriodAbbreviatureMonth4: "APR",
  statisticPeriodAbbreviatureMonth5: "MAY",
  statisticPeriodAbbreviatureMonth6: "JUNE",
  statisticPeriodAbbreviatureMonth7: "JUL",
  statisticPeriodAbbreviatureMonth8: "AVG",
  statisticPeriodAbbreviatureMonth9: "SEPT",
  statisticPeriodAbbreviatureMonth10: "OCT",
  statisticPeriodAbbreviatureMonth11: "NOV",
  statisticPeriodAbbreviatureMonth12: "DEC",
  statisticTitleSalesBlockDate: "Selected period",
  statisticTitleSalesBlockCount: "Quantity of goods",
  statisticTitleSalesBlockOffline: "Offline sales",
  statisticSellerBlockTitle: "Sales Report",
  statisticSalesBlockCountOption: "pcs",
  statisticSalesBlockOfflineOption: "%",
  statisticSalesBlockTippyProfit: "Click on amount to see revenue",
  statisticSalesBlockTippyRevenue: "Click on the amount to see the profit",
  statisticSalesBlockTippyPrecent:
    "Specifies the percentage of retail sales as a ratio to the store's online sales in the selected month.",

  //Page orderlist
  orderListTitleRetail: "Retail",
  orderListTitleReturn: "Return",
  orderListTitlePiece: "piece",
  orderListTitleNon: "No orders for the selected date",
  roductListTitleNon: "No sales on the selected date",

  //Toast
  titleToastSettingSuccess: "Changes saved",
  descToastSettingSuccess: "Don't forget to refresh the page",
  titleToastSettingError: "Changes not saved",
  descToastSettingError: "Check the entered data",
  titleToastSettingInfo: "No changes detected",
  descToastSettingInfo: "Check entered data",
  titleToastAccountDeleteError: "An error occurred",
  descToastAccountDeleteError: "Unable to delete account",
  titleToastAvatarUploadSuccess: "Avatar uploaded",
  descToastAvatarUploadSuccess: "Don't forget to refresh the page",
  titleToastAvatarUploadError: "Avatar not uploaded",
  descToastAvatarUploadError: "Try again later",
  titleToastAvatarDeleteSuccess: "Avatar deleted",
  descToastAvatarDeleteSuccess: "Don't forget to refresh the page",
  titleToastAvatarDeleteError: "Avatar not deleted",
  descToastAvatarDeleteError: "Try again later",

  //Modal
  titleModalDeleteUser: "Deleting account",
  textModalDeleteUser:
    "Do you really want to delete this user's account with no possibility of recovery?",
  titleModalLogoutUser: "Logout of account",
  textModalLogoutUser: "Do you really want to logout of this user's account?",
  titleModalDeleteAuthUser: "Delete Authorization",
  textModalDeleteAuthUser:
    "You really want to delete authorization of the account on this device. You will need to re-authorize on this device afterwards.",
  buttonModalCancel: "Cancel",
  buttonModalDelete: "Delete",
  buttonModalNo: "No",
  buttonModalYes: "Yes",
};

export default enJSON;
