import React from "react";
import styles from "./Header.module.sass";
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <NavLink to="/" className={styles.link}>
              <i className={styles.vsrap}></i>
            </NavLink>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.account}>
            <a href="/account/register" className={styles.link}>
              Регистрация
            </a>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.social}>
            <NavLink
              to="https://vk.com/vsrapru"
              rel="noopener noreferrer"
              target="_blank"
              className={styles.link}
            >
              <i className={styles.vk}></i>
            </NavLink>
            <NavLink
              rel="noopener noreferrer"
              className={styles.link}
              to="https://t.me/vsrapru"
              target="_blank"
            >
              <i className={styles.tg}></i>
            </NavLink>
            <NavLink
              rel="noopener noreferrer"
              className={styles.link}
              to="https://www.youtube.com/@vsrapru"
              target="_blank"
            >
              <i className={styles.youtube}></i>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
