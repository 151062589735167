import React from "react";
import axios from "../../../../axios";
import { useDispatch } from "react-redux";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import styles from "./Setting.module.sass";
import style from "../../../HotToast/Hottoast.module.sass";

import { getCategories } from "../../../../redux/slises/Ecwid/ecwid";
import { updateUsers } from "../../../../redux/slises/Admin/admin";
import SelectCollection from "./Select/Select";

import fetchAdminUserInfo from "../UserData/UserData";
import ModalDelete from "../../../Modal/DeleteAccount/ModalDelete";

function Setting({
  userId,
  userFirstName,
  userLastName,
  userEmail,
  userRoles,
  userAvatar,
  userToken,
  userPer,
  userExtendedOrder,
  userActivated,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [avatarUrl, setAvatarUrl] = React.useState("");
  const [avatarName, setAvatarName] = React.useState("");
  const [userName, setUserName] = React.useState(
    userFirstName + " " + userLastName
  );
  const [roles, setRoles] = React.useState(userRoles);
  const [firstName, setFirstName] = React.useState(userFirstName);
  const [lastName, setLastName] = React.useState(userLastName);
  const [token, setToken] = React.useState(userToken);
  const [artistPer, setArtistPer] = React.useState(userPer);
  const [extendedOrder, setExtendedOrder] = React.useState(userExtendedOrder);
  const [activated, setActivated] = React.useState(userActivated);
  const [password, setPassword] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = React.useState(false);

  const inputFileRef = React.useRef(null);

  const selectCollection = (token) => {
    setToken(token);
  };

  React.useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  React.useEffect(() => {
    dispatch(getCategories());

    return () => {
      toast.remove();
    };
  }, []);

  const role = [
    { value: "Admin", label: t("role_admin") },
    { value: "Redactor", label: t("role_editor") },
    { value: "Artist", label: t("role_artist") },
  ];

  const handleChangeFile = async (event) => {
    try {
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append("image", file);
      const { data } = await axios.post("/upload", formData);
      setAvatarUrl(data.url);
      setAvatarName(data.name);
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconSuccess}></div>
          <div className={style.toastTitle}>
            {t("titleToastAvatarUploadSuccess")}
            <p>{t("descToastAvatarUploadSuccess")}</p>
          </div>
        </div>
      );
    } catch (e) {
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastAvatarUploadError")}
            <p>{t("descToastAvatarUploadError")}</p>
          </div>
        </div>
      );
    }
  };

  const onClickRemoveImage = async (event) => {
    try {
      await axios.delete("/upload", { data: { avatarName } });
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconSuccess}></div>
          <div className={style.toastTitle}>
            {t("titleToastAvatarDeleteSuccess")}
            <p>{t("descToastAvatarDeleteSuccess")}</p>
          </div>
        </div>
      );
      setAvatarUrl("http://localhost:5000/api/uploads/no-avatar.png");
    } catch (error) {
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastAvatarDeleteError")}
            <p>{t("descToastAvatarDeleteError")}</p>
          </div>
        </div>
      );
    }
  };

  const handleOpenModal = () => setModalDeleteOpen(true);
  const handleCloseModal = () => setModalDeleteOpen(false);

  const handleConfirm = async () => {
    setModalDeleteOpen(false);
    try {
      await axios.delete(`/user/${userId}/delete`);
      window.history.back();
    } catch (error) {
      console.error(error);
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastAccountDeleteError")}
            <p>{t("descToastAccountDeleteError")}</p>
          </div>
        </div>
      );
    }
  };

  const handleCancel = () => {
    setModalDeleteOpen(false);
  };

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Проверить, были ли внесены изменения в форму
    if (
      avatarUrl === userAvatar &&
      token === userToken &&
      artistPer === userPer &&
      extendedOrder === userExtendedOrder &&
      activated === userActivated &&
      roles === userRoles &&
      firstName === userFirstName &&
      lastName === userLastName &&
      password === ""
    ) {
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastSettingInfo")}
            <p>{t("descToastSettingInfo")}</p>
          </div>
        </div>
      );
      return;
    }

    const updatedFormData = {
      avatarURL: avatarUrl,
      password: password,
      extendedOrder: extendedOrder,
      roles: roles,
      firstName: firstName,
      lastName: lastName,
      artistToken: token,
      artistPer: artistPer,
      activated: activated,
    };

    try {
      await dispatch(updateUsers({ userId, updatedData: updatedFormData }));
      setUserName(firstName + " " + lastName);
      // toast.custom(
      //   <div className={style.toastHot}>
      //     <div className={style.toastIconSuccess}></div>
      //     <div className={style.toastTitle}>
      //       {t("titleToastSettingSuccess")}
      //       <p>{t("descToastSettingSuccess")}</p>
      //     </div>
      //   </div>
      // );
      window.location.reload();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.custom(
          <div className={style.toastHot}>
            <div className={style.toastIconError}></div>
            <div className={style.toastTitle}>
              {t("titleToastSettingError")}
              <p>{t("descToastSettingError")}</p>
            </div>
          </div>
        );
      } else {
        toast.custom(
          <div className={style.toastHot}>
            <div className={style.toastIconError}></div>
            <div className={style.toastTitle}>
              {t("titleToastSettingError")}
              <p>{t("descToastSettingError")}</p>
            </div>
          </div>
        );
      }
    }
  }

  const handleRolesChange = (selectedOption) => {
    setRoles(selectedOption.value);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={styles.userSetting}>
      <Toaster position="bottom-right" reverseOrder={false} />
      <ModalDelete
        isOpen={isModalDeleteOpen}
        onRequestClose={handleCloseModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <div className={styles.userSettingItem}>
        <div className={styles.userSettingName}>
          <h2>{userName}</h2>
        </div>
        <div className={styles.userSettingAccount}>
          <div className={styles.userAvatarBlock}>
            <input
              ref={inputFileRef}
              type="file"
              onChange={handleChangeFile}
              hidden
            />
            {avatarUrl ? (
              <img height="60" width="60" src={avatarUrl} alt={userName} />
            ) : (
              <img src={userAvatar} height="60" width="60" alt={userName} />
            )}
            <div className={styles.userAvatarUpload}>
              <div className={styles.userAvatarButton}>
                <button
                  onClick={() => inputFileRef.current.click()}
                  className={styles.buttonUpload}
                >
                  {t("buttonUploadAvatar")}
                </button>
                <button
                  onClick={onClickRemoveImage}
                  className={styles.buttonRemove}
                >
                  {t("buttonDeleteAvatar")}
                </button>
              </div>
              <p className={styles.info}>{t("infoUploadAvatar")}</p>
            </div>
          </div>
          <div className={styles.buttonDeleteUser} onClick={handleOpenModal}>
            {t("buttonDeleteUser")}
          </div>
        </div>
      </div>
      <form
        onSubmit={handleFormSubmit}
        autoComplete="off"
        className={styles.userSettingGroup}
      >
        <div className={styles.userSettingItem}>
          <div className={styles.userSettingName}>
            <h3>{t("headerTitleUserSettingMerch")}</h3>
          </div>
          <div className={styles.userFormItemGroup}>
            <div className={styles.userFormItem}>
              <div className={styles.userFormItemTitle}>
                {t("labelUserSettingId")}
                <p>{t("labelDescUserSettingId")}</p>
              </div>
              <div className={styles.userFormItemInput}>
                <SelectCollection selectValue={selectCollection} />
              </div>
            </div>
            <div className={styles.userFormItem}>
              <div className={styles.userFormItemTitle}>
                {t("labelUserSettingPercent")}
                <p>{t("labelDescSettingPercent")}</p>
              </div>
              <div className={styles.userFormItemInput}>
                <input
                  value={artistPer}
                  className={styles.settingInput}
                  placeholder={t("placeholderUserPercent")}
                  onChange={(e) => setArtistPer(e.target.value)}
                />
              </div>
            </div>
            <div className={`${styles.userFormItem} checkbox-wrapper`}>
              <div className={styles.userFormItemTitle}>
                {t("labelUserSettingBlock")}
                <p>{t("labelDescUserSettingBlock")}</p>
              </div>
              <label
                className={`${styles.checkboxWrapper} ${styles.userFormItemInput}`}
              >
                <input
                  type="checkbox"
                  name="extendedOrder"
                  className={styles.checkbox}
                  checked={extendedOrder}
                  onChange={(e) => setExtendedOrder(e.target.checked)}
                />
                <div className={styles.checkboxSlider}>
                  <div className={styles.checkboxKnob}></div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className={styles.userSettingItem}>
          <div className={styles.userSettingName}>
            <h3>{t("headerTitleUserSettingUser")}</h3>
          </div>
          <div className={styles.userFormItemGroup}>
            <div className={styles.userFormItem}>
              <div className={styles.userFormItemTitle}>
                {t("labelUserSettingActivate")}
                <p>{t("labelDescUserSettingActivate")}</p>
              </div>
              <label
                className={`${styles.checkboxWrapper} ${styles.userFormItemInput}`}
              >
                <input
                  type="checkbox"
                  name="activated"
                  className={styles.checkbox}
                  checked={activated}
                  onChange={(e) => setActivated(e.target.checked)}
                />
                <div className={styles.checkboxSlider}>
                  <div className={styles.checkboxKnob}></div>
                </div>
              </label>
            </div>
            <div className={styles.userFormItem}>
              <div className={styles.userFormItemTitle}>
                {t("labelUserSettingEmail")}
                <p>{t("labelDescUserSettingEmail")}</p>
              </div>
              <div className={styles.userFormItemInput}>
                <input
                  className={styles.settingInput}
                  placeholder={t("placeholderUserEmail")}
                  value={userEmail}
                  autoComplete="username"
                  disabled
                />
              </div>
            </div>
            <div className={styles.userFormItem}>
              <div className={styles.userFormItemTitle}>
                {t("labelUserSettingGroup")}
                <p>{t("labelDescUserSettingGroup")}</p>
              </div>
              <div className={styles.userFormItemInput}>
                <Select
                  options={role}
                  value={role.find((group) => group.value === roles)}
                  onChange={handleRolesChange}
                  isSearchable={false}
                  className="select"
                  classNamePrefix="select"
                  onFocus={(event) => {
                    event.target.setAttribute("autocomplete", "off");
                    event.target.setAttribute("autocorrect", "off");
                    event.target.setAttribute("autocapitalize", "none");
                  }}
                />
              </div>
            </div>
            <div className={styles.userFormItem}>
              <div className={styles.userFormItemTitle}>Имя и Фамилия</div>
              <div className={styles.userFormItemInput}>
                <input
                  value={firstName}
                  className={styles.settingInput}
                  placeholder={t("placeholderUserName")}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  value={lastName}
                  className={styles.settingInput}
                  placeholder={t("placeholderUserSurname")}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className={styles.userFormItem}>
              <div className={styles.userFormItemTitle}>
                {t("labelUserSettingNewpass")}
                <p>{t("labelDescUserSettingNewpass")}</p>
              </div>
              <div className={styles.userFormItemInput}>
                <input
                  value={password}
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  className={styles.settingInput}
                  placeholder={t("placeholderUserNewpass")}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  onClick={handleTogglePassword}
                  className={styles.userFormItemInputPass}
                >
                  {showPassword ? (
                    <i className={styles.iconEyeClose}></i>
                  ) : (
                    <i className={styles.iconEye}></i>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.buttonBlock}>
            <button type="submit" className={styles.button}>
              {t("buttonSaveSettingUser")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default fetchAdminUserInfo(Setting);
