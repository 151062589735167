import React from "react";
import { useSelector } from "react-redux";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Skeleton from "../Skeleton";

const EcwidProduct = ({ productId, productName }) => {
  const img = useSelector((state) => {
    if (state.ecwid.status === "loading") {
      return 0;
    }
    if (state.ecwid.status === "loaded") {
      const filteredRevenue = Object.values(state.ecwid.product).filter(
        (revenue) => revenue.id === `${productId}`
      );
      return filteredRevenue.length > 0 ? filteredRevenue[0].image : 0;
    }

    return img;
  });

  const url = useSelector((state) => {
    if (state.ecwid.status === "loading") {
      return 0;
    }
    if (state.ecwid.status === "loaded") {
      const filteredRevenue = Object.values(state.ecwid.product).filter(
        (revenue) => revenue.id === `${productId}`
      );
      return filteredRevenue.length > 0 ? filteredRevenue[0].url : 0;
    }

    return url;
  });

  return img ? (
    <Tippy content={productName}>
      <a href={url} target={"_blank"} rel="noreferrer">
        <img src={img} alt={productName} />
      </a>
    </Tippy>
  ) : (
    <Tippy content={productName}>
      <a target={"_blank"} rel="noreferrer">
        <Skeleton />
      </a>
    </Tippy>
  );
};
export default EcwidProduct;
