import React from "react";
import { useSelector } from "react-redux";

const MonthCount = ({ selectedPeriod }) => {
  const count = useSelector((state) => {
    if (state.sales.status === "loading") {
      return 0;
    }
    if (state.sales.status === "loaded") {
      const filteredRevenue = Object.values(state.sales.monthCount).filter(
        (revenue) => revenue.month === `${selectedPeriod}`
      );
      return filteredRevenue.length > 0 ? filteredRevenue[0].count : 0;
    }

    return 0;
  });

  return count;
};

export default MonthCount;
