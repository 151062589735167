import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import EcwidProduct from "./Product/TopProductData";
import styles from "./TopProductItem.module.sass";
import { getProduct } from "../../../../../redux/slises/Ecwid/ecwid";

function TopProductItem({ id, name, total, count, period, percentage }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(getProduct(id));
  }, [period]);

  return (
    <div className={styles.topProductItem}>
      <div className={styles.itemProductImg}>
        <EcwidProduct productId={id} productName={name} title={name} />
        <div className={styles.itemProductCountMob}>
          <span>{count}</span>
        </div>
      </div>
      <div className={styles.topProductInfoBlock}>
        <div className={styles.topProductInfo}>
          <div className={styles.topProductName}>
            <span>{name}</span>
          </div>
          <div className={styles.topProductNumber}>
            <div className={styles.itemProductCount}>
              <span>{count}</span>
            </div>
            <div className={styles.itemProductTotal}>
              <span>{total.toLocaleString("ru-RU")}</span>
              <span>₽</span>
            </div>
          </div>
        </div>
        <div className={styles.statusTopProduct}>
          <div
            className={styles.statusTopProductLine}
            style={{ width: `${percentage}%` }}
          ></div>
          <div className={styles.statusTopProductLineBg}></div>
        </div>
      </div>
    </div>
  );
}

export default TopProductItem;
