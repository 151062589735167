import React from "react";
import { useSelector } from "react-redux";

const MonthPercentOffline = ({ selectedPeriod }) => {
  const percent = useSelector((state) => {
    if (state.sales.status === "loading") {
      return 0;
    }

    if (state.sales.status === "loaded") {
      const revenue = Object.values(state.sales.monthPercentOffline).find(
        (revenue) => revenue.month === `${selectedPeriod}`
      );

      // Возвращаем процент, если найден, иначе 0
      return revenue ? revenue.onlineToOfflineRatio : 0;
    }

    return 0; // Статус не загружен, возвращаем 0
  });

  return percent; // Возвращаем полученное значение
};

export default MonthPercentOffline;
