import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Header.module.sass";
import ButtonBack from "../../ButtonBack/ButtonBack";

function Header({ activeGroup }) {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = React.useState("");

  const handleButtonClick = (role) => {
    setActiveButton(role);
    activeGroup(role);
  };

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <ButtonBack />
        <div className={styles.titleBlock}>
          <div className={styles.titlePage}>
            <h1>{t("headerTitleUserSetting")}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
