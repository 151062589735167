import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

import "../Modal.modules.sass";

function ModalLogout({ isOpen, onRequestClose, onConfirm, onCancel }) {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={`modalContent ${isOpen ? "modalVisible" : ""}`}
      overlayClassName={`modalOverlay ${isOpen ? "modalVisible" : ""}`}
      appElement={document.getElementById("root")}
    >
      <div className="modalTitle">
        <h2>{t("titleModalLogoutUser")}</h2>
        <div className="modalClose" onClick={onCancel}>
          <i className="iconClose"></i>
        </div>
      </div>
      <div className="modalText">
        <p>{t("textModalLogoutUser")}</p>
      </div>
      <div className="modalButtons">
        <button className="buttonCancel" onClick={onCancel}>
          {t("buttonModalNo")}
        </button>
        <button className="buttonSelect" onClick={onConfirm}>
          {t("buttonModalYes")}
        </button>
      </div>
    </Modal>
  );
}

export default ModalLogout;
