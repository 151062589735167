import React from "react";
import Select from "react-select";
import styles from "./Select.module.sass";
import fetchAdminUserInfo from "../../UserData/UserData";
import { useSelector } from "react-redux";

function SelectCollection({ userToken, selectValue }) {
  const [selects, setSelects] = React.useState([{ id: 1, value: null }]);
  const collection = useSelector((state) => state.ecwid.collection);

  React.useEffect(() => {
    if (userToken && userToken.length > 0) {
      const initialSelects = userToken.map((selectedCollection, index) => ({
        id: index + 1,
        value: { label: selectedCollection, value: selectedCollection },
      }));
      setSelects(initialSelects);
    } else {
      // Если userToken пустой, сбрасываем селекты
      setSelects([{ id: 1, value: null }]);
    }
  }, [userToken]);

  const addSelect = () => {
    const newSelectId = selects.length + 1;
    setSelects([...selects, { id: newSelectId, value: null }]);
  };

  const removeSelect = (id) => {
    if (selects.length > 1) {
      setSelects(selects.filter((select) => select.id !== id));
    }
  };

  const handleTokenChange = (id, option) => {
    const updatedSelects = selects.map((select) =>
      select.id === id ? { ...select, value: option } : select
    );
    setSelects(updatedSelects);
  };

  const selectedTokens = selects
    .map((select) => (select.value ? select.value.label : null))
    .filter((value) => value !== null);

  React.useEffect(() => {
    selectValue(selectedTokens);
  }, [selects]);

  return (
    <>
      {selects.map((select) => (
        <div key={select.id} className={styles.userFormSelectCollectionBlock}>
          <Select
            key={select.id}
            options={collection}
            value={select.value}
            onChange={(option) => handleTokenChange(select.id, option)}
            isSearchable={false}
            className={`${styles.select} select`}
            classNamePrefix="select"
            placeholder="Выберите коллекцию"
            onFocus={(event) => {
              event.target.setAttribute("autocomplete", "off");
              event.target.setAttribute("autocorrect", "off");
              event.target.setAttribute("autocapitalize", "none");
            }}
          />
          {select.id === 1 && (
            <a onClick={addSelect} className={styles.addButton}>
              <i className={styles.iconAdd}></i>
            </a>
          )}
          {select.id !== 1 && (
            <a
              onClick={() => removeSelect(select.id)}
              className={styles.removeButton}
            >
              <i className={styles.iconDelete}></i>
            </a>
          )}
        </div>
      ))}
    </>
  );
}

export default fetchAdminUserInfo(SelectCollection);
