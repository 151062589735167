import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../../config.json";

export const ecwidInstance = axios.create({
  baseURL: config.ecwidApiUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers":
      "x-access-token, x-requested-with, origin, Content-Type, Accept",
  },
});

export const getCategories = createAsyncThunk("ecwid/collection", async () => {
  const { data } = await ecwidInstance.get(
    `${config.ecwidApiUrl}${config.ecwidShopId}/categories`,
    {
      params: {
        token: config.ecwidPublicToken,
        lang: "ru",
        parent: config.ecwidMainCollectionId,
        hidden_categories: false,
      },
    }
  );
  const collection = data.items.map((item) => {
    return {
      id: item.id,
      label: item.name,
      value: item.name,
      poster: item.thumbnailUrl,
    };
  });

  return collection;
});

export const getProduct = createAsyncThunk(
  "ecwid/product",
  async (productId) => {
    const { data } = await ecwidInstance.get(
      `${config.ecwidApiUrl}${config.ecwidShopId}/products/${productId}`,
      {
        params: {
          token: config.ecwidPublicToken,
          lang: "ru",
        },
      }
    );
    return { id: productId, image: data.thumbnailUrl, url: data.url };
  }
);

export const clearProduct = createAsyncThunk("ecwid/clearProduct", async () => {
  return;
});

const initialState = {
  collection: [],
  product: [],
  status: "loading",
};

const ecwidSlice = createSlice({
  name: "ecwid",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.collection = [];
        state.status = "loading";
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.collection = action.payload;
        state.status = "loaded";
      })
      .addCase(getCategories.rejected, (state) => {
        state.collection = [];
        state.status = "error";
      })
      .addCase(getProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        const { id, image, url } = action.payload;
        const existingProduct = state.product.find(
          (product) => product.id === id
        );
        if (!existingProduct) {
          state.product.push({ id, image, url });
        }
        state.status = "loaded";
      })
      .addCase(getProduct.rejected, (state) => {
        state.product = [];
        state.status = "error";
      })
      .addCase(clearProduct.fulfilled, (state) => {
        state.product = [];
      });
  },
});

export const ecwidReducer = ecwidSlice.reducer;
