import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import styles from "./Header.module.sass";
import ButtonBack from "../../../ButtonBack/ButtonBack";

function Header() {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <ButtonBack />
        <div className={styles.titleBlock}>
          <div className={styles.titlePage}>
            <h1>{t("headerTitleStatistic")}</h1>
          </div>
          <div className={styles.titleAction}>
            <NavLink
              to="/"
              className={({ isActive }) =>
                `${isActive ? styles.active : ""} ${styles.titleActionButton}`
              }
            >
              {t("headerButtonPageStatistic")}
            </NavLink>
            <NavLink
              to="/top"
              className={({ isActive }) =>
                `${isActive ? styles.active : ""} ${styles.titleActionButton}`
              }
            >
              {t("headerButtonPageTop")}
            </NavLink>
            <NavLink
              to="/orders"
              className={({ isActive }) =>
                `${isActive ? styles.active : ""} ${styles.titleActionButton}`
              }
            >
              {t("headerButtonPageOrders")}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
