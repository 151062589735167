import React from "react";
import { useDispatch } from "react-redux";
import fetchUserInfo from "../../components/User/UserData";
import axios from "../../axios";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { updateUser } from "../../redux/slises/User/user";
import styles from "../../components/User/Setting.module.sass";
import Header from "../../components/User/Header/Header";
import { logout } from "../../redux/slises/Auth/auth";
import SelectLang from "../../components/Language/Select";
import style from "../../components/HotToast/Hottoast.module.sass";
import ModalLogout from "../../components/Modal/LogoutAccount/ModalLogout";

function Setting({
  userId,
  userFirstName,
  userLastName,
  userEmail,
  userRoles,
  userAvatar,
  userCollection,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [avatarUrl, setAvatarUrl] = React.useState("");
  const [avatarName, setAvatarName] = React.useState("");
  const [userName, setUserName] = React.useState(
    userFirstName + " " + userLastName
  );
  const [firstName, setFirstName] = React.useState(userFirstName);
  const [lastName, setLastName] = React.useState(userLastName);
  const [oldPassword, setOldPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const inputFileRef = React.useRef(null);
  const [isModalLogoutOpen, setModalLogoutOpen] = React.useState(false);

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const handleChangeFile = async (event) => {
    try {
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append("image", file);
      const { data } = await axios.post("/upload", formData);
      setAvatarUrl(data.url);
      setAvatarName(data.name);
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconSuccess}></div>
          <div className={style.toastTitle}>
            {t("titleToastAvatarUploadSuccess")}
            <p>{t("descToastAvatarUploadSuccess")}</p>
          </div>
        </div>
      );
    } catch (e) {
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastAvatarUploadError")}
            <p>{t("descToastAvatarUploadError")}</p>
          </div>
        </div>
      );
    }
  };

  const onClickRemoveImage = async (event) => {
    try {
      await axios.delete("/upload", { data: { avatarName } });
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconSuccess}></div>
          <div className={style.toastTitle}>
            {t("titleToastAvatarDeleteSuccess")}
            <p>{t("descToastAvatarDeleteSuccess")}</p>
          </div>
        </div>
      );
      setAvatarUrl("http://localhost:5000/api/uploads/no-avatar.png");
    } catch (error) {
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastAvatarDeleteError")}
            <p>{t("descToastAvatarDeleteError")}</p>
          </div>
        </div>
      );
    }
  };

  const handleOpenModal = () => setModalLogoutOpen(true);
  const handleCloseModal = () => setModalLogoutOpen(false);

  const handleConfirm = async () => {
    setModalLogoutOpen(false);
    dispatch(logout());
    window.location.href = "/";
  };

  const handleCancel = () => {
    setModalLogoutOpen(false);
  };

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Проверить, были ли внесены изменения в форму
    if (
      avatarUrl === "" &&
      firstName === userFirstName &&
      lastName === userLastName &&
      password === ""
    ) {
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastSettingInfo")}
            <p>{t("descToastSettingInfo")}</p>
          </div>
        </div>
      );
      return;
    }

    // Обновляем данные формы
    const updatedFormData = {
      firstName: firstName,
      avatarURL: avatarUrl,
      lastName: lastName,
      oldPassword: oldPassword,
      password: password,
    };

    try {
      await dispatch(updateUser({ userId, updatedData: updatedFormData }));
      setUserName(firstName + " " + lastName);
      window.location.reload();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.custom(
          <div className={style.toastHot}>
            <div className={style.toastIconError}></div>
            <div className={style.toastTitle}>
              {t("titleToastSettingError")}
              <p>{t("descToastSettingError")}</p>
            </div>
          </div>
        );
      } else {
        toast.custom(
          <div className={style.toastHot}>
            <div className={style.toastIconError}></div>
            <div className={style.toastTitle}>
              {t("titleToastSettingError")}
              <p>{t("descToastSettingError")}</p>
            </div>
          </div>
        );
      }
    }
  }

  const roleTranslations = {
    Admin: t("role_admin"),
    Redactor: t("role_editor"),
    Artist: t("role_artist"),
  };

  React.useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <main className={styles.userSetting}>
      <div className={styles.containerUserSetting}>
        <Toaster position="bottom-right" reverseOrder={false} />
        <ModalLogout
          isOpen={isModalLogoutOpen}
          onRequestClose={handleCloseModal}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
        <Header />
        <div className={styles.settingBlock}>
          <div className={styles.userSetting}>
            <div className={styles.userSettingItem}>
              <div className={styles.userSettingName}>
                <h2>{userName}</h2>
              </div>
              <div className={styles.userSettingAccount}>
                <div className={styles.userAvatarBlock}>
                  <input
                    ref={inputFileRef}
                    type="file"
                    onChange={handleChangeFile}
                    hidden
                  />
                  {avatarUrl ? (
                    <img
                      height="60"
                      width="60"
                      src={avatarUrl}
                      alt={userName}
                    />
                  ) : (
                    <img
                      src={userAvatar}
                      height="60"
                      width="60"
                      alt={userName}
                    />
                  )}
                  <div className={styles.userAvatarUpload}>
                    <div className={styles.userAvatarButton}>
                      <button
                        onClick={() => inputFileRef.current.click()}
                        className={styles.buttonUpload}
                      >
                        {t("buttonUploadAvatar")}
                      </button>
                      <button
                        onClick={onClickRemoveImage}
                        className={styles.buttonRemove}
                      >
                        {t("buttonDeleteAvatar")}
                      </button>
                    </div>
                    <p className={styles.info}>{t("infoUploadAvatar")}</p>
                  </div>
                </div>
                <div
                  className={styles.buttonDeleteUser}
                  onClick={handleOpenModal}
                >
                  {t("buttonLogoutUser")}
                </div>
              </div>
            </div>
            <form
              onSubmit={handleFormSubmit}
              className={styles.userSettingGroup}
            >
              <div className={styles.userSettingItem}>
                <div className={styles.userSettingName}>
                  <h3>{t("headerTitleUserSettingPlatform")}</h3>
                </div>
                <div className={styles.userFormItemGroup}>
                  <div className={styles.userFormItem}>
                    <div className={styles.userFormItemTitle}>
                      {t("labelUserSettingLang")}
                      <p>{t("labelDescSettingLang")}</p>
                    </div>
                    <div className={styles.userFormItemInput}>
                      <SelectLang />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.userSettingItem}>
                <div className={styles.userSettingName}>
                  <h3>{t("headerTitleUserSettingUser")}</h3>
                </div>
                <div className={styles.userFormItemGroup}>
                  <div className={styles.userFormItem}>
                    <div className={styles.userFormItemTitle}>
                      {t("labelUserSettingEmail")}
                      <p>{t("labelDescUserSettingEmail")}</p>
                    </div>
                    <div className={styles.userFormItemInput}>
                      <input
                        className={styles.settingInput}
                        placeholder={t("placeholderUserEmail")}
                        value={userEmail}
                        autoComplete="username"
                        disabled
                      />
                    </div>
                  </div>
                  <div className={styles.userFormItem}>
                    <div className={styles.userFormItemTitle}>
                      {t("labelUserSettingGroup")}
                      <p>{t("labelDescUserSettingEmail")}</p>
                    </div>
                    <div className={styles.userFormItemInput}>
                      <input
                        className={styles.settingInput}
                        value={roleTranslations[userRoles]}
                        autoComplete="username"
                        disabled
                      />
                    </div>
                  </div>
                  <div className={styles.userFormItem}>
                    <div className={styles.userFormItemTitle}>
                      {t("labelUserSettingId")}
                      <p>{t("labelDescUserSettingEmail")}</p>
                    </div>
                    <div className={styles.userFormItemInput}>
                      <input
                        className={styles.settingInput}
                        placeholder={t("placeholderUserColletion")}
                        value={userCollection}
                        disabled
                      />
                    </div>
                  </div>
                  <div className={styles.userFormItem}>
                    <div className={styles.userFormItemTitle}>
                      {t("labelUserSettingName")}
                    </div>
                    <div className={styles.userFormItemInput}>
                      <input
                        value={firstName}
                        className={styles.settingInput}
                        placeholder={t("placeholderUserName")}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                      <input
                        value={lastName}
                        className={styles.settingInput}
                        placeholder={t("placeholderUserSurname")}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.userFormItem}>
                    <div className={styles.userFormItemTitle}>
                      {t("labelUserSettingOldpass")}
                      <p>{t("labelDescUserSettingOldpass")}</p>
                    </div>
                    <div className={styles.userFormItemInput}>
                      <input
                        value={oldPassword}
                        type={showPassword ? "text" : "password"}
                        autoComplete="off"
                        className={styles.settingInput}
                        placeholder={t("placeholderUserOldpass")}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                      <span
                        onClick={handleTogglePassword}
                        className={styles.userFormItemInputPass}
                      >
                        {showPassword ? (
                          <i className={styles.iconEyeClose}></i>
                        ) : (
                          <i className={styles.iconEye}></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className={styles.userFormItem}>
                    <div className={styles.userFormItemTitle}>
                      {t("labelUserSettingNewpass")}
                      <p>{t("labelDescUserSettingNewpass")}</p>
                    </div>
                    <div className={styles.userFormItemInput}>
                      <input
                        value={password}
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className={styles.settingInput}
                        placeholder={t("placeholderUserNewpass")}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        onClick={handleTogglePassword}
                        className={styles.userFormItemInputPass}
                      >
                        {showPassword ? (
                          <i className={styles.iconEyeClose}></i>
                        ) : (
                          <i className={styles.iconEye}></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.buttonBlock}>
                  <button type="submit" className={styles.button}>
                    {t("buttonSaveSettingUser")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default fetchUserInfo(Setting);
