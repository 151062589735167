import FingerprintJS from "@fingerprintjs/fingerprintjs";

const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + value + ";" + "expires=" + expires.toUTCString() + ";path=/";
};

export const getDeviceId = async () => {
  try {
    // Инициализация объекта FingerprintJS
    const fp = await FingerprintJS.load();

    // Получение ip пользователя
    const fetchIp = await fetch("https://api.ipify.org?format=json");
    const dataIp = await fetchIp.json();
    const userIp = dataIp.ip;

    // Получение уникального идентификатора устройства
    const result = await fp.get();
    const visitorId = result.visitorId;

    // Получение название устройства
    const userAgent = navigator.userAgent;
    const browsersGroup = {
      YaBrowser: "Yandex Browser",
      Firefox: "Mozilla Firefox",
      MSIE: "Internet Explorer",
      Trident: "Internet Explorer",
      Edge: "Microsoft Edge",
      Opera: "Opera",
      OPR: "Opera",
      Safari: "Safari",
      Chrome: "Google Chrome",
    };
    let browsersOther = "Other"; // Значение по умолчанию

    // Проверяем наличие каждого браузера в строке userAgent
    for (let key in browsersGroup) {
      if (userAgent.indexOf(key) !== -1) {
        browsersOther = browsersGroup[key];
        break; // Если нашли, выходим из цикла
      }
    }
    const browser = browsersOther;

    // Получение зоны устройства
    const timeZone = result.components.timezone.value;

    // Записываем уникальный идентификатор устройства в куки на 30 дней
    setCookie("deviceId", visitorId, 30);
    setCookie("browser", browser, 30);
    setCookie("timeZone", timeZone, 30);
    setCookie("userIp", userIp, 30);

    return visitorId;
  } catch (error) {
    console.error(error);
  }
};
