import React, { Component } from "react";

import styles from "./Bg.module.sass";

class AnimateBg extends Component {
  componentDidMount() {
    const existingAnotherScript = document.querySelector(
      'script[src="/js/three.min.js"]'
    );

    if (existingAnotherScript) {
      existingAnotherScript.remove();
    }

    const anotherScript = document.createElement("script");
    anotherScript.src = "/js/three.min.js";
    anotherScript.async = true;
    document.body.appendChild(anotherScript);

    const existingBgScript = document.querySelector(
      'script[src="/js/bg-animate.js"]'
    );

    if (existingBgScript) {
      existingBgScript.remove();
    }

    const bgScript = document.createElement("script");
    bgScript.src = "/js/bg-animate.js";
    bgScript.async = true;
    document.body.appendChild(bgScript);
  }

  render() {
    return (
      <>
        <section className={styles.bgBlock}>
          <div id="bg-animate" className={styles.bgActive}></div>
        </section>
      </>
    );
  }
}

export default AnimateBg;
