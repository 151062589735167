export const generateUniqueID = () => {
  return `_${Math.random().toString(36).substr(2, 9)}`;
};

export const orderGeneration = (orders) => {
  const formattedOrders = orders
    .map((item) => ({
      productID: item.ID,
      orderID: item.IDЗаказ,
      date: item.Дата,
      name: item.Номенклатура,
      variant: item.Характеристика,
      count: item.Количество,
      price: item.Цена,
      total: Math.floor(item.Сумма),
    }))
    .reduce((acc, item) => {
      let orderId;
      if (item.count >= 0) {
        orderId = item.orderID || `Rozn-${generateUniqueID()}`;
      } else {
        orderId = `Vozv-${item.orderID}`;
      }

      if (!acc[orderId]) {
        acc[orderId] = {
          orderID: orderId,
          date: item.date, // Сохраняем полную дату для сортировки
          month: item.date.split("-").slice(0, 2).join("-"),
          count: 0,
          total: 0,
          products: [],
        };
      }

      acc[orderId].products.push({
        name: item.name,
        variant: item.variant,
        productId: item.productID,
        count: item.count,
        price: item.price,
        total: item.total,
      });

      acc[orderId].count += item.count;
      acc[orderId].total += item.total;
      return acc;
    }, {});

  // Сортируем массив заказов по дате
  return Object.values(formattedOrders).sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
};
