const ruJSON = {
  // title: "VSRAPBOY - Личный кабинет партнера",
  // title2: "Последние заказы",
  // title3: "Настройки профиля",
  // title4: "Ой, кажется, вы потерялись!",
  // title5: "ONLY FOR",
  // title6: "Личный кабинет партнеров и артистов VSRAP",
  // title7: "Мерчендайз",
  // title8: "Дистрибуция",
  // title9: "Интеграции",
  // title10: "Общие настройки",
  // title100: "Настройки продаж",
  // title11: "Настройка процентов выручки",
  // title12: "Все пользователи",
  // title13: "Редактирование пользователя",
  // title14: "ОТЧЕТ ПО ПРОДАЖАМ",
  // title15: "ТОВАРЫ",
  // title16: "ВЫРУЧКА",
  // title17: "ПРИБЫЛЬ",
  // title18: "ПЕРИОД",
  // title19: "Регистрация аккаунта",
  // title20: "Восстановление пароля",
  // title21: "Аккаунт не активирован",
  // title22: "Вход в аккаунт",
  // title23: "Прибыль за указанный период",
  // title24: "График прибыли по месяцам",
  // title25: "Выручка за указанный период",
  // title26: "Прибыль за текущий год",
  // title27: "Прибыль за прошлый год",
  // title28: "Количество проданных товаров:",
  // title29: "Настройки мерчендайза",
  // title30: "или",

  // text2: "Отчеты о продажах, подсчет прибыли",
  // text3: "Статистика музыкальных релизов",
  // text4: "Вся рекламная аналитика",
  // text5: "Ваша учетная запись еще не активирована администрацией платформы.",
  //
  // developer: "Сделано с любовью в",
  //
  // btn: "Мерчендайз",
  // btn2: "Пользователи",
  // btn3: "Настройки",
  // btn4: "vsrap.shop",
  // btn5: "Дистрибуция",
  // btn6: "Интеграции",
  // btn7: "PageBoy",
  // btn8: "Войти",
  // btn9: "Выйти из профиля",
  // btn10: "Вернуться назад",
  // btn11: "Настройки профиля",
  // btn12: "Сохранить изменения",
  // btn13: "Вернуться на главную",
  // btn14: "Далее",
  // btn15: "Все",
  // btn16: "Админы",
  // btn17: "Редакторы",
  // btn18: "Артисты",
  // btn19: "Пользователи",
  // btn20: "Да",
  // btn21: "Нет",
  // btn22: "Удалить аккаунт",
  // btn23: "Войти в аккаунт",
  // btn24: "Регистрация",
  // btn25: "Забыли пароль?",
  // btn26: "Зарегистрироваться",
  // btn27: "Восстановить пароль",
  // btn28: "Войти с помощью Вконтакте",
  // btn29: "Войти с помощью Google",
  //
  // label: "E-mail:",
  // label2: "ID артиста:",
  // label3: "Имя:",
  // label4: "Фамилия:",
  // label5: "Старый пароль:",
  // label6: "Новый пароль:",
  // label7: "Стандартный процент выручки:",
  // label8: "Средний процент выручки:",
  // label9: "Высокий процент выручки:",
  // label10: "Стандартный порог выручки:",
  // label11: "Высокий порог выручки:",
  // label12: "Группа:",
  // label13: "Отображать блок выручки в отчетах о продажах",
  // label14: "Процент выручки:",
  // label15: "ID коллекции:",
  // label16: "Подтвержденный аккаунт",
  //
  // placeholder: "Например: info@mail.ru",
  // placeholder2: "Например: IVANOV",
  // placeholder3: "Например: Иван",
  // placeholder4: "Например: Иванов",
  // placeholder5: "",
  // placeholder6: "",
  // placeholder7: "Например: 20",
  // placeholder8: "Например: 22",
  // placeholder9: "Например: 25",
  // placeholder10: "Поиск пользователя",
  // placeholder11: "Ваш E-mail",
  // placeholder12: "Пароль",
  // placeholder13: "Имя",
  // placeholder14: "Фамилия",
  // placeholder15: "Повторите пароль",
  // placeholder16: "Например: 1500000",
  // placeholder17: "Например: 3000000",
  // placeholder18: "Выберите коллекцию",
  //
  // role_admin: "Админ",
  // role_editor: "Редактор",
  // role_artist: "Артист",
  //
  // info: "Сохраняем...",
  // info2: "Удаляем...",
  // info3: "Вы точно хотите удалить аккаунт?",
  // info4: "Нажмите на блок чтобы увидеть выручку",
  // info5: "Нажмите на блок чтобы увидеть прибыль",
  // info6: "Нажмите на блок чтобы увидеть прибыль за прошлый год",
  // info7: "Нажмите на блок чтобы увидеть прибыль за текущий год",
  //
  // error: "Аватар не загружен",
  // error2: "Аватар не удален",
  // error4: "Изменения не обнаружены",
  // error5: "Произошла ошибка при сохранении изменений",
  // error6: "Пользователей по вашему запросу не найдено",
  // error7: "Не удалось получить список пользователей",
  // error8: "Произошла ошибка при удалении аккаунта",
  // error10: "Ошибка при получении уникального идентификатора устройства:",
  // error11: "Данные по продажам не были получены",
  // error12: "Заказы на указанную дату не найдены",
  // error13: "Вход не произведен, возможно введены неверные данные аккаунта",
  // error14: "Введите имя",
  // error15: "Имя слишком длинное",
  // error16: "Введите фамилию",
  // error17: "Фамилия слишком длинная",
  // error18: "Введите адрес электронной почты",
  // error19: "Введите корректный адрес электронной почты",
  // error20: "Придумайте пароль",
  // error21: "Длина пароль минимум 6 символов",
  // error22: "Повторно введите пароль",
  // error23: "Пароли должны совпадать",
  // error24: "Письмо уже отправлено в течение последних 5 минут!",
  // error25: "Письмо отправляется один раз в 5 минут",
  // error26: "Пользователь с таким email не найден",
  //
  // success: "Аватар загружен!",
  // success2: "Аватар удален!",
  // success4: "Изменения сохранены",
  // success5: "Ссылка для сброса пароля успешно отправлена!",
  // success6: "Ссылка для сброса пароля не отправлена!",
  //
  // order1: "ГОД",
  // order2: "Возврат",
  // order3: "Розница",
  // order4: "шт",
  //
  // homepage1: "Войти в кабинет",
  // homepage2: "Личный кабинет артистов и партнеров VSRAP",
  // homepage3: "Продажи \n мерчендайза",
  // homepage4: "Все отчеты по продажам в одном месте",
  // homepage5: "Статистика \n по дистрибуции",
  // homepage6: "Данные со всех музыкальных площадок",
  // homepage7: "Проще чем кажется",
  // homepage8: "Как начать пользоваться VSRAPBOY",
  // homepage9: "Для доступа к платформе ты должен выполнить три простых действия",
  // homepage10: "Регистрация аккаунта",
  // homepage11: "Зарегистрируйся при помощи социальных сетей или почты",
  // homepage12: "Подтверждение профиля",
  // homepage13: "Дождись подтверждения профиля администрацией платформы",
  // homepage14: "Выбор раздела",
  // homepage15: "Выбери интересующий раздел и отслеживай статистику",
  // homepage16: "Актуальная информация",
  // homepage17: "Обновление данных происходит в реальном времени",
  // homepage18:
  //   "Данные онлайн продаж поступают мгновенно, по дистрибуции - после каждого отчета",
  // homepage19: "Личный кабинет",
  // homepage20: "Мобильная и десктопная версия",
  // homepage21:
  //   "Платформа адаптивна к большинству современных устройств и разрешений.",
  // homepage22: "Фильтр статистики по интересующей дате",
  // homepage23: "Все отчеты можно отфильтровать по дате, за год или месяц",
  //
  // homepage24: "Личный кабинет артистов и партнеров VSRAP",
  // homepage25: "Выбери интересующий раздел и отслеживай статистику",

  //
  // title2: "Последние заказы",
  // title3: "Настройки профиля",
  // title4: "Ой, кажется, вы потерялись!",
  // title5: "ONLY FOR",
  // title6: "Личный кабинет партнеров и артистов VSRAP",
  // title7: "Мерчендайз",
  // title8: "Дистрибуция",
  // title9: "Интеграции",
  // title10: "Общие настройки",
  // title100: "Настройки продаж",
  // title11: "Настройка процентов выручки",
  // title12: "Все пользователи",
  // title13: "Редактирование пользователя",
  // title14: "ОТЧЕТ ПО ПРОДАЖАМ",
  // title15: "ТОВАРЫ",
  // title16: "ВЫРУЧКА",
  // title17: "ПРИБЫЛЬ",
  // title18: "ПЕРИОД",
  // title19: "Регистрация аккаунта",
  // title20: "Восстановление пароля",
  // title21: "Аккаунт не активирован",
  // title22: "Вход в аккаунт",
  // title23: "Прибыль за указанный период",
  // title24: "График прибыли по месяцам",
  // title25: "Выручка за указанный период",
  // title26: "Прибыль за текущий год",
  // title27: "Прибыль за прошлый год",
  // title28: "Количество проданных товаров:",
  // title29: "Настройки мерчендайза",
  // title30: "или",

  //Title page
  titleMain: "VSRAPBOY - Личный кабинет партнера",
  titleMainCabinet: "VSRAPBOY",
  titleStatisctiс: "Статистика продаж",
  titleAllSettingPlatform: "Общие настройки сайта",
  titleAllUsers: "Все пользователи",
  titleUserSetting: "Настройки пользователя",
  titleUserActivate: "Активация аккаунта",

  //NotFound page
  titleNotFound: "Ой, кажется, вы потерялись!",
  textNotFound:
    "Страница в данный момент не доступна, или у вас нет доступа к данном разделу.",

  //Roles
  role_admin: "Админ",
  role_editor: "Редактор",
  role_artist: "Артист",

  //Sidebar
  sidebarNavTitleSales: "Продажи",
  sidebarNavItemStats: "Статистика",
  sidebarNavItemTopSales: "Топ продаж",
  sidebarNavItemRecentOrders: "Последние заказы",
  sidebarNavItemUsers: "Пользователи",
  sidebarNavItemSettings: "Настройки",
  sidebarNavTitlePageBoy: "PageBoy",
  sidebarNavItemAddPage: "Добавить страницу",
  sidebarNavItemPages: "Страницы",
  sidebarNavItemPosts: "Посты",
  sidebarAccountBtn: "Войти",
  sidebarShopBtn: "Vsrap.shop",

  //Header merch
  headerTitleStatistic: "Продажи",
  headerButtonPageStatistic: "Статистика",
  headerButtonPageTop: "Топ продаж",
  headerButtonPageOrders: "Последние заказы",

  //Header userlist
  headerTitleUsersList: "Пользователи",
  headerButtonUsersAll: "Все",
  headerButtonUsersAdmin: "Админы",
  headerButtonUsersRedactor: "Редакторы",
  headerButtonUsersArtist: "Артисты",
  headerButtonUsersUser: "Пользователи",

  //Header usersetting
  headerTitleUserSetting: "Настройки пользователя",
  headerTitleUserSettingMerch: "Настройки продаж",
  headerTitleUserSettingUser: "Настройки профиля",
  headerTitleUserSettingPlatform: "Настройки сайта",
  headerTitleUserSettingAuth: "Авторизации пользователя",
  headerButtonUserAccount: "Профиль",
  headerButtonUserDevice: "Авторизации",
  buttonUploadAvatar: "Загрузить фото",
  buttonDeleteAvatar: "Удалить фото",
  infoUploadAvatar:
    "Изображение форматом JPG, JPEG или PNG. Размером не больше 100МБ.",
  buttonDeleteUser: "Удалить аккаунт",
  buttonLogoutUser: "Выйти из аккаунта",
  buttonSaveSettingUser: "Сохранить",
  labelUserSettingLang: "Язык сайта",
  labelDescSettingLang:
    "Выберите язык, который будет отображаться на всем сайте",
  labelUserSettingId: "ID коллекций",
  labelDescUserSettingId:
    "ID коллекций ecwid, которые относится к данному пользователю",
  labelUserSettingPercent: "Процент выручки",
  labelDescSettingPercent:
    "Если процент указан, то будет считаться только этот процент",
  labelUserSettingBlock: "Блок выручки",
  labelDescUserSettingBlock:
    "Отображать для пользователя блок выручки в отчетах о продажах",
  labelUserSettingName: "Имя и Фамилия",
  labelUserSettingActivate: "Активировать профиль",
  labelDescUserSettingActivate:
    "Если выключен, то пользователь не получит доступ к платформе",
  labelUserSettingEmail: "Email",
  labelDescUserSettingEmail:
    "Данную настройку может поменять только администратор платформы",
  labelUserSettingGroup: "Группа",
  labelDescUserSettingGroup: "Группа к которой относится пользователь",
  labelUserSettingNewpass: "Новый пароль",
  labelDescUserSettingNewpass: "Должен содержать минимум 6 символов",
  labelUserSettingOldpass: "Старый пароль",
  labelDescUserSettingOldpass: "Состоит минимум из 6 символов",
  placeholderUserNull: "",
  placeholderUserColletion: "Выберите коллекцию",
  placeholderUserEmail: "Например: info@mail.ru",
  placeholderUserName: "Например: Иван",
  placeholderUserSurname: "Например: Иванов",
  placeholderUserPercent: "Например: 20",
  placeholderUserNewpass: "Введите новый пароль",
  placeholderUserOldpass: "Введите старый пароль",

  //Header platformsetting
  headerTitlePlatformSetting: "Настройки сайта",
  headerButtonPlatformAll: "Общие",
  headerButtonPlatformSales: "Продажи",
  headerTitlePlatformSettingAll: "Общие настройки платформы",
  headerTitleUserSettingEcwid: "Настройки ECWID",
  headerTitleUserSettingSales: "Настройка процентов выручки",
  labelPlatformSettingUrl: "Домен сайта",
  labelDescPlatformSettingUrl:
    "Главный домен сайта, от которого будет отталкиваться вся платформа",
  labelPlatformSettingDev: "Режим разработки",
  labelDescPlatformSettingDev:
    "Переключит адрес backend`a с домена на localhost",
  labelPlatformSettingTitle: "Название (Title) сайта:",
  labelDescPlatformSettingTitle:
    "Будет использоваться во всех названиях страниц",
  labelPlatformSettingDesc: "Описание (Description) сайта",
  labelDescPlatformSettingDesc: "Краткое описание, не более 200 символов",
  labelPlatformSettingCopyright: "Копирайты",
  labelDescPlatformSettingCopyright:
    "Пока используются только на главной странице",
  labelPlatformSettingApi: "Адрес API",
  labelDescPlatformSettingApi: "Адрес для подключения по api к ecwid",
  labelPlatformSettingShopId: "Shop ID",
  labelDescPlatformSettingShopId: "ID магазина на ecwid",
  labelPlatformSettingPublicToken: "Public Token",
  labelDescPlatformSettingPublicToken:
    "Публичный токен для подключения к ecwid по api",
  labelPlatformSettingSecretToken: "Secret Token",
  labelDescPlatformSettingSecretToken:
    "Секретный токен для подключения к ecwid по api",
  labelPlatformSettingCollectionId: "ID коллекции",
  labelDescPlatformSettingCollectionId:
    "ID самой главной коллекции ecwid, в которую вложены все остальные наши коллекции",
  labelSalesSettingPercentLow: "Стандартный процент",
  labelDescSalesSettingPercentLow:
    "Самый нижний процент выручки для расчета продаж артистов",
  labelSalesSettingPercentMiddle: "Повышенный процент",
  labelDescSalesSettingPercentMiddle:
    "Средний процент выручки для расчета продаж артистов",
  labelSalesSettingPercentHigh: "Высокий процент",
  labelDescSalesSettingPercentHigh:
    "Максимальный процент выручки для расчета продаж артистов",
  labelSalesSettingTotalLow: "Стандартный порог выручки",
  labelDescSalesSettingTotalLow:
    "Минимальная сумма для увеличения процента при расчете продаж артистов",
  labelSalesSettingTotalHigh: "Высокий порог выручки",
  labelDescSalesSettingTotalHigh:
    "Максимальная сумма для увеличения процента при расчете продаж артистов",
  placeholderPlatformUrl: "Например: vsrapboy.com",
  placeholderPlatformTitle: "Например: Главная страница",
  placeholderPlatformDesc: "Например: Лучшая платформа во вселенной",
  placeholderPlatformCopyright: "Например: VSRAP самый крутой",
  placeholderPlatformApi: "Например: ecwid.com",
  placeholderPlatformToken: "Например: asd5asdas5dasd2223asd",
  placeholderPlatformPercent: "Например: 20",
  placeholderPlatformPercentMiddle: "Например: 22",
  placeholderPlatformPercentHigh: "Например: 25",
  placeholderPlatformTotalLow: "Например: 1500000",
  placeholderPlatformTotalHigh: "Например: 3000000",

  //Page userlist
  userlistErrorNot: "Пользователей по вашему запросу не найдено",
  userlistErrorUpload: "Не удалось получить список пользователей",
  userlistInputPlaceholder: "Поиск пользователя",

  //Page statistic
  statisticTitleBlockTop: "Топ продаж",
  statisticButtonBlockTop: "Все товары",
  statisticTitleBlockOrder: "Последние заказы",
  statisticButtonBlockOrder: "Все заказы",
  statisticTitleBlockYear: "Прибыль за выбранный год",
  statisticTitleBlockPeriodProfit: "Прибыль за указанный период",
  statisticTitleBlockPeriodRevenue: "Выручка за указанный период",
  statisticPeriodAbbreviatureMonth1: "ЯНВ",
  statisticPeriodAbbreviatureMonth2: "ФЕВ",
  statisticPeriodAbbreviatureMonth3: "МАР",
  statisticPeriodAbbreviatureMonth4: "АПР",
  statisticPeriodAbbreviatureMonth5: "МАЙ",
  statisticPeriodAbbreviatureMonth6: "ИЮН",
  statisticPeriodAbbreviatureMonth7: "ИЮЛ",
  statisticPeriodAbbreviatureMonth8: "АВГ",
  statisticPeriodAbbreviatureMonth9: "СЕН",
  statisticPeriodAbbreviatureMonth10: "ОКТ",
  statisticPeriodAbbreviatureMonth11: "НОЯ",
  statisticPeriodAbbreviatureMonth12: "ДЕК",
  statisticTitleSalesBlockDate: "Выбранный период",
  statisticTitleSalesBlockCount: "Кол-во товаров",
  statisticTitleSalesBlockOffline: "Офлайн продажи",
  statisticSellerBlockTitle: "Отчет по продажам",
  statisticSalesBlockCountOption: "шт.",
  statisticSalesBlockOfflineOption: "%",
  statisticSalesBlockTippyProfit: "Нажмите на сумму чтобы увидеть выручку",
  statisticSalesBlockTippyRevenue: "Нажмите на сумму чтобы увидеть прибыль",
  statisticSalesBlockTippyPrecent:
    "Указывается процент продаж розницы в соотношении к онлайн продажам магазина в выбранном месяце.",

  //Page orderlist
  orderListTitleRetail: "Розница",
  orderListTitleReturn: "Возврат",
  orderListTitlePiece: "шт.",
  orderListTitleNon: "Заказы на выбранную дату отсутствуют",
  productListTitleNon: "Продажи на выбранную дату отсутствуют",

  //Page Sign
  signInTitle: "Вход в аккаунт",
  signUpTitle: "Регистрация аккаунта",
  signPasswordTitle: "Восстановление пароля",

  //Toast
  titleToastSettingSuccess: "Изменения сохранены",
  descToastSettingSuccess: "Не забудьте обновить страницу",
  titleToastSettingError: "Изменения не сохранены",
  descToastSettingError: "Проверьте введенные данные",
  titleToastSettingInfo: "Изменения не обнаружены",
  descToastSettingInfo: "Проверьте введенные данные",
  titleToastAccountDeleteError: "Произошла ошибка",
  descToastAccountDeleteError: "Не получается удалить аккаунт",
  titleToastAvatarUploadSuccess: "Аватар загружен",
  descToastAvatarUploadSuccess: "Не забудьте обновить страницу",
  titleToastAvatarUploadError: "Аватар не загружен",
  descToastAvatarUploadError: "Попробуйте еще раз позже",
  titleToastAvatarDeleteSuccess: "Аватар удален",
  descToastAvatarDeleteSuccess: "Не забудьте обновить страницу",
  titleToastAvatarDeleteError: "Аватар не удален",
  descToastAvatarDeleteError: "Попробуйте еще раз позже",

  titleToastSignIngError: "Вход не выполнен",
  descToastSignIngError: "Возможно введены неверные данные аккаунта",

  titleToastSignUpgError: "Регистрация не выполнена",
  descToastSignUpgError: "Проверьте правильность заполнения полей",

  //Modal
  titleModalDeleteUser: "Удаление аккаунта",
  textModalDeleteUser:
    "Вы действительно хотите удалить аккаунт данного пользователя без возможности восстановления?",
  titleModalLogoutUser: "Выход из аккаунта",
  textModalLogoutUser: "Вы действительно хотите выйти из аккаунта?",
  titleModalDeleteAuthUser: "Удаление авторизации",
  textModalDeleteAuthUser:
    "Вы действительно хотите удалить авторизацию аккаунт на данном устройствe. После этого нужна будет повторная авторизация на данном устройстве.",
  buttonModalCancel: "Отмена",
  buttonModalDelete: "Удалить",
  buttonModalNo: "Нет",
  buttonModalYes: "Да",
};

export default ruJSON;
