import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = ({ ...props }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth > 1340 ? (
    <ContentLoader
      speed={2}
      width={1012}
      height={50}
      viewBox="0 0 1012 50"
      backgroundColor="#262626"
      foregroundColor="#333333"
      {...props}
    >
      <rect x="0" y="0" rx="10" ry="10" width="50" height="50" />
      <rect x="66" y="8" rx="2" ry="2" width="130" height="10" />
      <rect x="66" y="33" rx="2" ry="2" width="946" height="4" />
      <rect x="932" y="8" rx="2" ry="2" width="80" height="10" />
    </ContentLoader>
  ) : windowWidth > 1200 ? (
    <ContentLoader
      speed={2}
      width={315}
      height={50}
      viewBox="0 0 315 50"
      backgroundColor="#262626"
      foregroundColor="#333333"
      {...props}
    >
      <rect x="0" y="0" rx="10" ry="10" width="50" height="50" />
      <rect x="66" y="8" rx="2" ry="2" width="130" height="10" />
      <rect x="66" y="33" rx="2" ry="2" width="249" height="4" />
      <rect x="235" y="8" rx="2" ry="2" width="80" height="10" />
    </ContentLoader>
  ) : (
    <ContentLoader
      speed={2}
      width={315}
      height={50}
      viewBox="0 0 315 50"
      backgroundColor="#262626"
      foregroundColor="#333333"
      {...props}
    >
      <rect x="0" y="0" rx="10" ry="10" width="50" height="50" />
      <rect x="66" y="8" rx="2" ry="2" width="130" height="10" />
      <rect x="66" y="33" rx="2" ry="2" width="249" height="4" />
      <rect x="235" y="8" rx="2" ry="2" width="80" height="10" />
    </ContentLoader>
  );
};

export default Skeleton;
