import React from "react";
import { useSelector } from "react-redux";
import fetchSettingOptions from "../../../../Admin/Setting/SettingData/SettingData";

const MonthGain = ({
  settingPercentLow,
  settingPercentMiddle,
  settingPercentHigh,
  settingTotalLow,
  settingTotalMiddle,
  selectedPeriod,
}) => {
  const total = useSelector((state) => {
    if (state.sales.status === "loading") {
      return 0;
    }
    if (state.sales.status === "loaded") {
      const filteredRevenue = Object.values(state.sales.monthTotal).filter(
        (revenue) => revenue.month === `${selectedPeriod}`
      );
      return filteredRevenue.length > 0 ? filteredRevenue[0].total : 0;
    }

    return [];
  });

  let percentage;

  switch (true) {
    case total < settingTotalLow:
      percentage = settingPercentLow;
      break;
    case total < settingTotalMiddle:
      percentage = settingPercentMiddle;
      break;
    default:
      percentage = settingPercentHigh;
      break;
  }

  const sumTotalPercentage = Math.round((total / 100) * percentage)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return sumTotalPercentage.toLocaleString();
};

export default fetchSettingOptions(MonthGain);
