import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { selectIsAuth } from "../../redux/slises/Auth/auth";

import Nav from "./Nav/Nav";
import Account from "./Account/Account";

import styles from "./Sidebar.module.sass";

function Sidebar() {
  const { t } = useTranslation();
  const isAuth = useSelector(selectIsAuth);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const handleBurgerClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  const updateBodyOverflow = () => {
    if (isMobileMenuOpen && window.innerWidth <= 1200) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  React.useEffect(() => {
    updateBodyOverflow();

    const handleResize = () => {
      updateBodyOverflow();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      document.body.style.overflow = "";
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileMenuOpen]);

  return (
    <aside
      className={`${styles.aside} ${isMobileMenuOpen ? styles.openMenu : ""}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.bukerBlock}>
            <i className={styles.bukerImg}></i>
          </div>
          <NavLink to="/" className={styles.link}>
            <div className={styles.logo}>
              <i className={styles.vsrap}></i>
            </div>
          </NavLink>
          <div
            className={`${styles.burgerMob} ${
              isMobileMenuOpen ? styles.open : ""
            }`}
            onClick={handleBurgerClick}
          >
            <span className={styles.iconBurger}></span>
            <span className={styles.iconBurger}></span>
            <span className={styles.iconBurger}></span>
          </div>
        </div>
        <div
          className={`${styles.sidebarContent} ${
            isMobileMenuOpen ? styles.open : ""
          }`}
        >
          <Nav handleLinkClick={handleLinkClick} />
          <div className={styles.footer}>
            <div className={styles.menuItem}>
              <NavLink
                to="https://vsrap.shop/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.menuIcon}>
                  <i className={styles.iconVsrapShop}></i>
                </div>
                <span className={styles.button}>{t("sidebarShopBtn")}</span>
              </NavLink>
            </div>
            <div className={styles.separator}></div>
            {isAuth ? (
              <Account handleLinkClick={handleLinkClick} />
            ) : (
              <>
                <Link to="/account/login" className={styles.button}>
                  {t("sidebarAccountBtn")}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
