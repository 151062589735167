import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = ({ ...props }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth > 1340 ? (
    <ContentLoader
      speed={2}
      width={344}
      height={74}
      viewBox="0 0 344 74"
      backgroundColor="#262626"
      foregroundColor="#333333"
      {...props}
    >
      <rect x="81" y="46" rx="3" ry="3" width="52" height="8" />
      <circle cx="41" cy="37" r="25" />
      <rect x="81" y="23" rx="3" ry="3" width="163" height="12" />
    </ContentLoader>
  ) : windowWidth > 1200 ? (
    <ContentLoader
      speed={2}
      width={315}
      height={74}
      viewBox="0 0 315 74"
      backgroundColor="#262626"
      foregroundColor="#333333"
      {...props}
    >
      <rect x="81" y="46" rx="3" ry="3" width="52" height="8" />
      <circle cx="41" cy="37" r="25" />
      <rect x="81" y="23" rx="3" ry="3" width="193" height="12" />
    </ContentLoader>
  ) : (
    <ContentLoader
      speed={2}
      width={457}
      height={74}
      viewBox="0 0 457 74"
      backgroundColor="#262626"
      foregroundColor="#333333"
      {...props}
    >
      <rect x="81" y="46" rx="3" ry="3" width="52" height="8" />
      <circle cx="41" cy="37" r="25" />
      <rect x="81" y="23" rx="3" ry="3" width="360" height="12" />
    </ContentLoader>
  );
};

export default Skeleton;
