import React from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/Sidebar/Sidebar";
import Preloader from "./components/Preloader/Preloader";
import Homepage from "./pages/Homepage/Homepage";
import Title from "./utils/TitlePage";
import Merchandising from "./pages/Merch/Statistic/Statistic";
import TopSales from "./pages/Merch/Top/TopProduct";
import LastOrders from "./pages/Merch/LastOrders/LastOrders";
import NotFound from "./pages/NotFound/NotFound";
import UsersList from "./pages/Admin/UsersList/UsersList";
import Option from "./pages/Admin/Setting/Setting";
import Sales from "./pages/Admin/Setting/Sales";

import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Activate from "./pages/Account/Activate";
import Password from "./pages/Account/Password";
import Setting from "./pages/Account/Setting";
import UserEdit from "./pages/Admin/User/UserSetting";
import UserAuth from "./pages/Admin/User/UserAuth";

import { getDeviceId } from "./utils/DeviceId";
import { useTranslation } from "react-i18next";
import LangIdentificator from "./components/Language/LangIdentificator";

import styles from "./sass/Main.module.sass";

import "./sass/app.sass";
import {
  checkAuth,
  selectIsActivated,
  selectIsAdmin,
  selectIsAuth,
} from "./redux/slises/Auth/auth";

function App({ userId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const isAdmin = useSelector(selectIsAdmin) === "Admin" || "Redactor";
  const isActivated = useSelector(selectIsActivated);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getDeviceId();
  }, []);

  React.useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    document.documentElement.style.overflow = loading ? "hidden" : "inherit";
    document.body.style.overflow = loading ? "hidden" : "inherit";
  }, [loading]);

  const renderMainStructure = (children, title) => (
    <>
      <Title title={title} />
      <div className={styles.main}>
        <div className={styles.containerFluid}>
          <Sidebar />
          {children}
        </div>
      </div>
    </>
  );

  const renderHomeStructure = (children, title) => (
    <>
      <Title title={title} />
      <div className={styles.main}>
        <div className={styles.containerFluid}>{children}</div>
      </div>
    </>
  );

  const renderProtectedRoutes = () => (
    <>
      <Route
        path="/"
        element={renderMainStructure(
          <Merchandising />,
          t("titleStatisctiс") + " | " + t("titleMainCabinet")
        )}
      />
      <Route
        path="/top"
        element={renderMainStructure(
          <TopSales />,
          t("titleStatisctiс") + " | " + t("titleMainCabinet")
        )}
      />
      <Route
        path="/orders"
        element={renderMainStructure(
          <LastOrders />,
          t("titleStatisctiс") + " | " + t("titleMainCabinet")
        )}
      />
      <Route
        path="/user/edit"
        element={renderMainStructure(
          <Setting />,
          t("titleUserSetting") + " | " + t("titleMainCabinet")
        )}
      />
      <Route
        path="*"
        element={renderHomeStructure(<NotFound />, t("titleMain"))}
      />
      {isAdmin && renderAdminRoutes()}
    </>
  );

  const renderAdminRoutes = () => (
    <>
      <Route
        path="/admin/users"
        element={renderMainStructure(
          <UsersList />,
          t("titleAllUsers") + " | " + t("titleMainCabinet")
        )}
      />
      <Route
        path="/admin/users/:userId"
        element={renderMainStructure(
          <UserEdit />,
          t("titleUserSetting") + " | " + t("titleMainCabinet")
        )}
      />
      <Route
        path="/admin/users/auth/:userId"
        element={renderMainStructure(
          <UserAuth />,
          t("titleUserSetting") + " | " + t("titleMainCabinet")
        )}
      />
      <Route
        path="/admin/setting"
        element={renderMainStructure(
          <Option />,
          t("titleAllSettingPlatform") + " | " + t("titleMainCabinet")
        )}
      />
      <Route
        path="/admin/setting/sales"
        element={renderMainStructure(
          <Sales />,
          t("titleAllSettingPlatform") + " | " + t("titleMainCabinet")
        )}
      />
    </>
  );

  const renderPublicRoutes = () => (
    <>
      <Route
        path="/"
        element={
          <>
            <Title title={t("titleMain")} />
            <Homepage />
          </>
        }
      />
      <Route
        path="/account/login"
        element={
          <>
            <Title title={t("signInTitle") + " | " + t("titleMainCabinet")} />
            <SignIn />
          </>
        }
      />
      <Route
        path="/account/register"
        element={
          <>
            <Title title={t("signUpTitle") + " | " + t("titleMainCabinet")} />
            <SignUp />
          </>
        }
      />
      <Route
        path="/account/forgot-password"
        element={
          <>
            <Title
              title={t("signPasswordTitle") + " | " + t("titleMainCabinet")}
            />
            <Password />
          </>
        }
      />
      <Route
        path="/account/reset-password"
        element={
          <>
            <Title
              title={t("signPasswordTitle") + " | " + t("titleMainCabinet")}
            />
            <Password />
          </>
        }
      />
      <Route
        path="*"
        element={renderHomeStructure(
          <NotFound />,
          t("titleMainCabinet") + " | " + t("titleMainCabinet")
        )}
      />
    </>
  );

  const renderActivateRoutes = () => (
    <>
      <Route
        path="/"
        element={renderHomeStructure(
          <Activate />,
          t("titleUserActivate") + " | " + t("titleMainCabinet")
        )}
      />
      {/*<Route*/}
      {/*  path="/user/edit"*/}
      {/*  element={*/}
      {/*    <>*/}
      {/*      <Title*/}
      {/*        title={t("titleUserSetting") + " | " + t("titleMainCabinet")}*/}
      {/*      />*/}
      {/*      <NotFound />*/}
      {/*    </>*/}
      {/*  }*/}
      {/*/>*/}
      <Route
        path="*"
        element={renderHomeStructure(
          <NotFound />,
          t("titleMainCabinet") + " | " + t("titleMainCabinet")
        )}
      />
    </>
  );

  return (
    <>
      <Preloader loading={loading} />
      <LangIdentificator />
      <Routes>
        {isAuth && isActivated && renderProtectedRoutes()}
        {isAuth && !isActivated && renderActivateRoutes()}
        {!isAuth && renderPublicRoutes()}
      </Routes>
    </>
  );
}

export default App;
