import React from "react";
import axios from "../../../axios";

async function fetchAdminUserAuthFetch() {
  const url = window.location.href;
  const urlParts = url.split("/");
  const urlId = urlParts[urlParts.length - 1];
  const response = await axios.get(`/admin/user/auth/${urlId}`);
  return response.data;
}

function fetchAdminUserAuth(WrappedComponent) {
  return function UserInfoWrapper(props) {
    const [userAuthData, setUserAuthData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetchAdminUserAuthFetch();
          setUserAuthData(response);
        } catch (error) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }, []);

    return (
      !isLoading &&
      !isError &&
      userAuthData && (
        <WrappedComponent userAuthList={userAuthData} {...props} />
      )
    );
  };
}

export default fetchAdminUserAuth;
