import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../../components/NotFound/NotFound.module.sass";

// import AnimateBg from "../../components/Sign/Bg/Bg";

function PageError() {
  const { t } = useTranslation();
  return (
    <div className={styles.notFound}>
      <div className={styles.notFoundBlock}>
        <div className={styles.notFoundContentBlock}>
          <div className={styles.logo}>
            <span>ONLY FOR</span>
            <a href="/" className="">
              <i className={styles.iconLogo}></i>
            </a>
          </div>
          <div className={styles.title}>
            <h1>{t("titleNotFound")}</h1>
          </div>
          <div className={styles.text}>
            <p>{t("textNotFound")}</p>
          </div>
          <div className={styles.buttonBlock}>
            <a href="/" className={styles.button}>
              Вернуться на главную
            </a>
          </div>
        </div>
      </div>
      <section className={styles.bgBlock}>
        <div id="bg-animate" className={styles.bgActive}></div>
      </section>
      {/*<AnimateBg />*/}
    </div>
  );
}

export default PageError;
