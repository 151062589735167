import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { clearProduct } from "../../../../redux/slises/Ecwid/ecwid";
import Skeleton from "./Skeleton";
import TopProductItem from "./Item/TopProductItem";

import styles from "./TopProduct.module.sass";

const TopProduct = ({ selectedPeriod, selectedCollection }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visibleOrders, setVisibleOrders] = React.useState(15);

  React.useEffect(() => {
    setVisibleOrders(5);
    dispatch(clearProduct());
  }, [selectedPeriod, dispatch]);

  const productsTop = useSelector((state) => {
    if (state.sales.status === "loading") {
      return [...Array(5)].map((_, index) => <Skeleton key={index} />);
    }

    if (state.sales.status === "loaded") {
      const products = Object.values(
        state.sales.productsTop[selectedPeriod] || {}
      );

      // Сортируем продукты по количеству продаж
      const sortedProducts = products.sort((a, b) => b.count - a.count);

      // Находим максимальное и минимальное количество продаж
      const maxCount = Math.max(...products.map((product) => product.count));
      const minCount = Math.min(...products.map((product) => product.count));

      // Рассчитываем проценты для каждого продукта
      const productsWithPercentages = sortedProducts.map((product) => {
        const percentage =
          ((product.count - minCount) / (maxCount - minCount)) * 100 + 1;
        return {
          ...product,
          percentage: Math.round(percentage),
        };
      });

      return productsWithPercentages
        .slice(0, visibleOrders)
        .map((product) => (
          <TopProductItem
            key={product.productId}
            id={product.productId}
            name={product.name}
            total={product.total}
            count={product.count}
            period={selectedPeriod}
            artist={selectedCollection}
            percentage={product.percentage}
          />
        ));
    }

    return null;
  });

  return (
    <div className={styles.topProductList}>
      {Array.isArray(productsTop) && productsTop.length > 0 ? (
        <>{productsTop}</>
      ) : (
        <p className={styles.info}>{t("productListTitleNon")}</p>
      )}
    </div>
  );
};

export default TopProduct;
