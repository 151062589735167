import React from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PeriodSelectors from "../../../components/Merch/Statistic/Period/Period";
import LastOrder from "../../../components/Merch/Statistic/LastOrder/LastOrder";
import TopProduct from "../../../components/Merch/Statistic/TopProduct/TopProduct";
import fetchUserInfo from "../../../components/User/UserData";

import Header from "../../../components/Merch/Statistic/Header/Header";
import styles from "../../../components/Merch/Statistic/Statistic.module.sass";

function Statistic({ userRoles, userCollection }) {
  const { t } = useTranslation();
  const currentYear = moment().year();
  const currentMonth = moment().format("MM");
  const currentMonthName = moment().format("MMMM");
  const сurrentDate = currentYear + "-" + currentMonth;

  const [selectedPeriod, setSelectedPeriod] = React.useState(сurrentDate);
  const [selectedCollection, setSelectedCollection] = React.useState();
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonthName);
  const [selectedYear, setSelectedYear] = React.useState(currentYear);

  const blockRef = React.useRef(null);

  const selectMonth = (selectedMonth) => {
    setSelectedMonth(selectedMonth);
  };

  const selectYear = (selectedYear) => {
    setSelectedYear(selectedYear);
  };

  const selectData = (selectedPeriod) => {
    setSelectedPeriod(selectedPeriod);
  };

  const handleCollectionSelect = (artist) => {
    setSelectedCollection(artist);
    setSelectedPeriod(сurrentDate);
  };

  return (
    <main className={styles.merch}>
      <div className={styles.containerMerch}>
        <Header />
        <div className={styles.content}>
          <div className={styles.contentOrders}>
            <div className={styles.contentOrdersBlock}>
              <div className={styles.contentOrdersBlockTitle}>
                <div className={styles.titleOrdersBlock}>
                  <h2>{t("statisticTitleBlockTop")}</h2>
                  <span className={styles.contentOrdersBlockPeriod}>
                    {selectedMonth} {selectedYear}
                  </span>
                </div>
                <div className={styles.bittonAllOrdersBlock}>
                  <NavLink to="/top" className={styles.link}>
                    {t("statisticButtonBlockTop")}
                  </NavLink>
                </div>
              </div>
              <div className={styles.topProduct}>
                <TopProduct
                  selectedPeriod={selectedPeriod}
                  selectedCollection={selectedCollection}
                />
              </div>
            </div>
            <div className={styles.contentOrdersBlock}>
              <div
                className={`${styles.contentOrdersBlockTitle} ${styles.orderTitle}`}
              >
                <div className={styles.titleOrdersBlock}>
                  <h2>{t("statisticTitleBlockOrder")}</h2>
                  <span className={styles.contentOrdersBlockPeriod}>
                    {selectedMonth} {selectedYear}
                  </span>
                </div>
                <div className={styles.bittonAllOrdersBlock}>
                  <NavLink to="/orders" className={styles.link}>
                    {t("statisticButtonBlockOrder")}
                  </NavLink>
                </div>
              </div>
              <div className={styles.orders}>
                <LastOrder
                  selectedPeriod={selectedPeriod}
                  selectedCollection={selectedCollection}
                />
              </div>
            </div>
          </div>
          <div className={styles.rightBlock} ref={blockRef}>
            <PeriodSelectors
              selectToData={selectData}
              selectToYear={selectYear}
              selectToMonth={selectMonth}
              onCollectionSelect={handleCollectionSelect}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default fetchUserInfo(Statistic);
