import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../axios";
// import orderGenerationCache from "./Utils/orderGenerationCache";
import { orderGeneration } from "./Utils/orderGeneration";
import { calculateProductsTop } from "./Utils/calculatedProductTop";
import { calculatedMonthPercentOffline } from "./Utils/calculatedMonthPercentOffline";
import { calculatedMonthCount } from "./Utils/calculatedMonthCount";
import { calculatedMonthTotal } from "./Utils/calculatedMonthTotal";
import { calculatedYearTotal } from "./Utils/calculatedYearTotal";

export const fetchOrders = createAsyncThunk(
  "orders",
  async (updatedArtistToken) => {
    if (!updatedArtistToken) {
      return [];
    }

    try {
      const { data } = await axios.get(
        `/orders?userToken=${updatedArtistToken}`
      );
      return { orders: data, artist: updatedArtistToken };
    } catch (e) {
      throw e.response?.data?.message;
    }
  }
);

const initialState = {
  orders: {},
  monthTotal: [],
  yearTotal: [],
  monthPercentOffline: [],
  monthCount: [],
  productsTop: [],
  status: "loading",
};

const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.orders = {};
        state.monthTotal = [];
        state.yearTotal = [];
        state.monthPercentOffline = [];
        state.monthCount = [];
        state.productsTop = [];
        state.status = "loading";
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        const { orders, artist } = action.payload;
        // state.orders[artist] = orderGenerationCache(orders, artist);
        state.orders[artist] = orderGeneration(orders, artist);
        state.productsTop = calculateProductsTop(state.orders[artist]);
        state.monthPercentOffline = calculatedMonthPercentOffline(
          state.orders[artist]
        );
        state.monthCount = calculatedMonthCount(state.orders[artist]);
        state.monthTotal = calculatedMonthTotal(state.orders[artist]);
        state.yearTotal = calculatedYearTotal(state.orders[artist]);
        state.status = "loaded";
      })
      .addCase(fetchOrders.rejected, (state) => {
        state.orders = [];
        state.monthTotal = [];
        state.yearTotal = [];
        state.monthPercentOffline = [];
        state.monthCount = [];
        state.productsTop = [];
        state.status = "error";
      });
  },
});

export const salesReducer = salesSlice.reducer;
