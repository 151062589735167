import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "swiper/css";
import "swiper/css/navigation";

import fetchUserInfo from "../../../User/UserData";
import MonthTotal from "./Numbers/MonthTotal";
import MonthGain from "./Numbers/MonthGain";
import MonthTotalNoMask from "./Numbers/MonthTotalNoMask";
import MonthTotalAbb from "./Numbers/MonthTotalAbb";
import MonthCount from "./Numbers/MonthCount";
import MonthPercentOffline from "./Numbers/MonthPercentOffline";
import TotalYear from "./TotalYear/TotalYear";

import styles from "./Period.module.sass";
import Seller from "./Seller/Seller";

function PeriodSelectors({
  selectToData,
  selectToYear,
  selectToMonth,
  userExtendedOrder,
  onCollectionSelect,
}) {
  const { t } = useTranslation();
  const [activePeriod, setActivePeriod] = React.useState("");
  const [activeYear, setActiveYear] = React.useState("");
  const [activeMonth, setActiveMonth] = React.useState("");
  const [isProfit, setIsProfit] = React.useState(true);

  const oldYearsNumb = moment().subtract(1, "year").year();
  const currentYear = moment().year();
  const currentMonth = moment().month();
  const currentMonthWord = moment().format("MMMM");

  React.useEffect(() => {
    const monthNumber = currentMonth + 1;
    const selectedZeroActivePeriod = `${currentYear}-${monthNumber
      .toString()
      .padStart(2, "0")}`;
    setActivePeriod(selectedZeroActivePeriod);
    setActiveYear(currentYear);
    setActiveMonth(currentMonthWord);
  }, [currentMonth, currentMonthWord, currentYear]);

  const months = [];
  const monthAbbreviations = [
    t("statisticPeriodAbbreviatureMonth1"),
    t("statisticPeriodAbbreviatureMonth2"),
    t("statisticPeriodAbbreviatureMonth3"),
    t("statisticPeriodAbbreviatureMonth4"),
    t("statisticPeriodAbbreviatureMonth5"),
    t("statisticPeriodAbbreviatureMonth6"),
    t("statisticPeriodAbbreviatureMonth7"),
    t("statisticPeriodAbbreviatureMonth8"),
    t("statisticPeriodAbbreviatureMonth9"),
    t("statisticPeriodAbbreviatureMonth10"),
    t("statisticPeriodAbbreviatureMonth11"),
    t("statisticPeriodAbbreviatureMonth12"),
  ];

  for (let month = 0; month <= currentMonth; month++) {
    const monthName = moment().month(month).format("MMMM");
    const capitalizedMonthName =
      monthName.charAt(0).toUpperCase() + monthName.slice(1);

    const monthNumber = (month + 1).toString().padStart(2, "0"); // Форматируем номер месяца
    const monthObj = {
      name: monthName,
      abbname: monthAbbreviations[month],
      number: monthNumber,
      year: currentYear,
    };

    months.push(monthObj); // Добавляем месяц в массив
  }
  // Переворачиваем массив месяцев в самом конце
  months.reverse();

  const previousYear = currentYear - 1; // предыдущий год
  const previousMonth = 11; // последний месяц прошлого года (декабрь)

  const oldMonths = [];

  for (let month = 0; month <= previousMonth; month++) {
    const monthName = moment().year(previousYear).month(month).format("MMMM");
    const monthNumber = (month + 1).toString().padStart(2, "0"); // Форматируем номер месяца

    const monthObj = {
      name: monthName,
      abbname: monthAbbreviations[month], // Используем заранее заданные сокращения
      number: monthNumber,
      year: previousYear,
    };

    oldMonths.push(monthObj); // Добавляем месяц в массив
  }

  // Сортируем и переворачиваем массив месяцев в самом конце
  oldMonths.sort((a, b) => a.number - b.number).reverse();

  const onClickPeriod = (year, month, name) => {
    const selectedActivePeriod = `${year}-${month.toString()}`;
    setActivePeriod(selectedActivePeriod);
    const selectedPeriod = `${year}-${month.toString().padStart(2, "0")}`;
    selectToData(selectedPeriod);
    const selectedYear = `${year}`;
    selectToYear(selectedYear);
    setActiveYear(selectedYear);
    const selectedMonth = `${name.toString().padStart(2, "0")}`;
    selectToMonth(selectedMonth);
    setActiveMonth(selectedMonth);
  };

  const interactiveHeight = useSelector((state) => {
    if (state.sales.status === "loading") {
      const columnElements = document.querySelectorAll(
        "[id='AbbMonthTotalColumn']"
      );
      columnElements.forEach((column) => {
        column.style.height = "5px";
      });
      return;
    }

    if (state.sales.status !== "loaded") {
      return;
    }

    const monthTotals = document.querySelectorAll("[id='AbbMonthTotal']");
    const monthValues = [];

    monthTotals.forEach((total) => {
      const totalElement = total.querySelector(".period-block-item-total");
      monthValues.push(parseInt(totalElement.textContent));
    });

    const maxValue = Math.max(...monthValues);
    const minValue = Math.min(...monthValues);

    const columnElements = document.querySelectorAll(
      "[id='AbbMonthTotalColumn']"
    );

    columnElements.forEach((column) => {
      const totalElement = column.parentElement.querySelector(
        "[id='AbbMonthTotal'] .period-block-item-total"
      );

      const totalValue = parseInt(totalElement.textContent);

      if (totalValue === 0) {
        column.style.height = "5px";
      } else if (totalValue.length === 0) {
        column.style.height = "5px";
      } else if (totalValue === maxValue) {
        column.style.height = "70px";
      } else if (totalValue === minValue) {
        column.style.height = "5px";
      } else {
        // Рассчитываем высоту в пикселях в соотношении с максимальным и минимальным значениями
        const heightPixels =
          ((totalValue - minValue) / (maxValue - minValue)) * 70;

        // Округляем высоту до ближайшего целого числа
        const roundedHeight = Math.round(heightPixels);

        // Устанавливаем высоту, убедившись, что она не меньше 5 пикселей
        column.style.height = Math.max(roundedHeight, 5) + "px";
      }
    });
  });

  const handleProfitClick = () => {
    setIsProfit(!isProfit);
  };

  const handleCollectionSelect = (artist) => {
    onCollectionSelect(artist);
    const monthNumber = currentMonth + 1;
    const selectedZeroActivePeriod = `${currentYear}-${monthNumber
      .toString()
      .padStart(2, "0")}`;
    setActivePeriod(selectedZeroActivePeriod);
  };

  return (
    <>
      <div className={styles.statisticBlock}>
        <div className={styles.statisticBlockHeader}>
          <Seller onCollectionSelect={handleCollectionSelect} />
        </div>
        <div className={styles.statsBlock}>
          <div className={styles.statsItem}>
            <span className={styles.statsItemInfo}>
              {activeMonth} {activeYear}
            </span>
            <div className={styles.statsItemDesc}>
              {t("statisticTitleSalesBlockDate")}
            </div>
          </div>
          <div className={styles.statsItem}>
            <span className={styles.statsItemInfo}>
              {activePeriod ? <MonthCount selectedPeriod={activePeriod} /> : 0}
              <span>{t("statisticSalesBlockCountOption")}</span>
            </span>
            <div className={styles.statsItemDesc}>
              {t("statisticTitleSalesBlockCount")}
            </div>
          </div>
          <div className={styles.statsItem}>
            <span className={styles.statsItemInfo}>
              <MonthPercentOffline selectedPeriod={activePeriod} />
              {t("statisticSalesBlockOfflineOption")}
              <Tippy content={t("statisticSalesBlockTippyPrecent")}>
                <i className={styles.iconInfo}></i>
              </Tippy>
            </span>
            <div className={styles.statsItemDesc}>
              {t("statisticTitleSalesBlockOffline")}
            </div>
          </div>
        </div>
        {userExtendedOrder ? (
          <>
            {isProfit ? (
              <div className={styles.totalBlock} onClick={handleProfitClick}>
                <div className={styles.totalNumber}>
                  <span>
                    {activePeriod ? (
                      <MonthTotal selectedPeriod={activePeriod} />
                    ) : (
                      0
                    )}
                  </span>
                  <p className={styles.totalCurrent}>₽</p>
                </div>
                <div className={styles.totalButton}>
                  <p>{t("statisticTitleBlockPeriodProfit")}</p>
                  <Tippy content={t("statisticSalesBlockTippyProfit")}>
                    <i className={styles.iconInfo}></i>
                  </Tippy>
                </div>
              </div>
            ) : (
              <div className={styles.totalBlock} onClick={handleProfitClick}>
                <div className={styles.totalNumber}>
                  <span>
                    {activePeriod ? (
                      <MonthGain selectedPeriod={activePeriod} />
                    ) : (
                      0
                    )}
                  </span>
                  <p className={styles.totalCurrent}>₽</p>
                </div>
                <div className={styles.totalButton}>
                  <p>{t("statisticTitleBlockPeriodRevenue")}</p>
                  <Tippy content={t("statisticSalesBlockTippyRevenue")}>
                    <i className={styles.iconInfo}></i>
                  </Tippy>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles.totalBlock}>
              <div className={`${styles.totalNumber} ${styles.noLink}`}>
                <span>
                  {activePeriod ? (
                    <MonthTotal selectedPeriod={activePeriod} />
                  ) : (
                    0
                  )}
                </span>
                <p className={styles.totalCurrent}>₽</p>
              </div>
              <div className={styles.totalButton}>
                <p>{t("statisticTitleBlockPeriodProfit")}</p>
              </div>
            </div>
          </>
        )}
        <div className={styles.statisticBlockPeriod}>
          <div className={styles.statisticBlockPeriodList}>
            {months.map((month) => (
              <div
                className={
                  activePeriod === `${currentYear}-${month.number}`
                    ? `${styles.statisticBlockPeriodItem} ${styles.active}`
                    : `${styles.statisticBlockPeriodItem}`
                }
                data-period={`${currentYear}-${month.number}`}
                key={month.abbname}
              >
                <a
                  className={styles.statisticBlockPeriodBtn}
                  data-period={`${currentYear}-${month.number}`}
                  onClick={() =>
                    onClickPeriod(currentYear, month.number, month.name)
                  }
                  id="AbbMonthTotal"
                >
                  <span
                    className={`${styles.statisticBlockPeriodTotal} period-block-item-total`}
                    id="AbbMonthTotalNoMask"
                  >
                    {activePeriod ? (
                      <MonthTotalNoMask
                        selectedPeriod={`${currentYear}-${month.number}`}
                      />
                    ) : (
                      0
                    )}
                  </span>
                  <div className={styles.statisticBlockPeriodBeautiful}>
                    {activePeriod ? (
                      <MonthTotalAbb
                        selectedPeriod={`${currentYear}-${month.number}`}
                      />
                    ) : (
                      0
                    )}
                  </div>
                  <span
                    className={styles.statisticBlockPeriodColumn}
                    id="AbbMonthTotalColumn"
                    style={{
                      height: "5px",
                    }}
                  ></span>
                  <span className={styles.statisticBlockPeriodMonth}>
                    {month.abbname}
                  </span>
                </a>
              </div>
            ))}
            {oldMonths.map((month) => (
              <div
                className={
                  activePeriod === `${oldYearsNumb}-${month.number}`
                    ? `${styles.statisticBlockPeriodItem} ${styles.active}`
                    : `${styles.statisticBlockPeriodItem}`
                }
                data-period={`${oldYearsNumb}-${month.number}`}
                key={month.abbname}
              >
                <a
                  className={styles.statisticBlockPeriodBtn}
                  data-period={`${oldYearsNumb}-${month.number}`}
                  onClick={() =>
                    onClickPeriod(oldYearsNumb, month.number, month.name)
                  }
                  id="AbbMonthTotal"
                >
                  <span
                    className={`${styles.statisticBlockPeriodTotal} period-block-item-total`}
                    id="AbbMonthTotalNoMask"
                  >
                    {activePeriod ? (
                      <MonthTotalNoMask
                        selectedPeriod={`${oldYearsNumb}-${month.number}`}
                      />
                    ) : (
                      0
                    )}
                  </span>
                  <div className={styles.statisticBlockPeriodBeautiful}>
                    {activePeriod ? (
                      <MonthTotalAbb
                        selectedPeriod={`${oldYearsNumb}-${month.number}`}
                      />
                    ) : (
                      0
                    )}
                  </div>
                  <span
                    className={styles.statisticBlockPeriodColumn}
                    id="AbbMonthTotalColumn"
                    style={{
                      height: "5%",
                    }}
                  ></span>
                  <span className={styles.statisticBlockPeriodMonth}>
                    {month.abbname}
                  </span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <TotalYear
        currentData={currentYear}
        activeData={activePeriod}
        oldData={oldYearsNumb}
      />
    </>
  );
}

export default fetchUserInfo(PeriodSelectors);
