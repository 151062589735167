import React from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import "./ModalCollection.modules.sass";
import { getCategories } from "../../../redux/slises/Ecwid/ecwid";
import fetchUserInfo from "../../User/UserData";

function ModalCollection({
  isOpen,
  onRequestClose,
  onCancel,
  userToken,
  userRoles,
  userCollection,
  onCollectionSelect,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeCollection, setActiveCollection] = React.useState(userToken);

  React.useEffect(() => {
    dispatch(getCategories());
  }, []);

  const collection = useSelector((state) => state.ecwid.collection);

  const handleArtistClick = (label) => {
    setActiveCollection(label);
    onCollectionSelect(label);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={`modalContent modalCollection ${isOpen ? "modalVisible" : ""}`}
      overlayClassName={`modalOverlay ${isOpen ? "modalVisible" : ""}`}
      appElement={document.getElementById("root")}
    >
      <div className="modalTitle">
        <div className="modalClose" onClick={onCancel}>
          <i className="iconClose"></i>
        </div>
      </div>
      <div className="modalText">
        {userRoles === "Artist" ? (
          <div className="collectionList">
            {collection
              .filter((category) => userCollection.includes(category.label)) // Фильтруем коллекции
              .map((category) => (
                <div
                  key={category.id}
                  className={
                    activeCollection === category.label
                      ? "collectionItem active"
                      : "collectionItem"
                  }
                >
                  <button
                    className="collectionItemButton"
                    onClick={() => {
                      handleArtistClick(category.label);
                      onCancel();
                    }}
                  >
                    <img
                      key={category.id}
                      src={
                        category.poster
                          ? category.poster
                          : `${process.env.REACT_APP_API_URL}/uploads/no-avatar.png`
                      }
                      height="24"
                      width="24"
                      alt={category.label}
                    />
                    <span className="caption-2">{category.label}</span>
                  </button>
                </div>
              ))}
          </div>
        ) : (
          <div className="collectionList">
            {collection.map((category) => (
              <div
                key={category.id}
                className={
                  activeCollection === category.label
                    ? "collectionItem active"
                    : "collectionItem"
                }
              >
                <button
                  className="collectionItemButton"
                  onClick={() => {
                    handleArtistClick(category.label);
                    onCancel();
                  }}
                >
                  <img
                    key={category.id}
                    src={
                      category.poster
                        ? category.poster
                        : `${process.env.REACT_APP_API_URL}/uploads/no-avatar.png`
                    }
                    height="24"
                    width="24"
                    alt={category.label}
                  />
                  <span className="caption-2">{category.label}</span>
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default fetchUserInfo(ModalCollection);
