import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import styles from "./Header.module.sass";
import ButtonBack from "../../../ButtonBack/ButtonBack";

function Header({ activeGroup, userId }) {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = React.useState("");

  const handleButtonClick = (role) => {
    setActiveButton(role);
    activeGroup(role);
  };

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <ButtonBack />
        <div className={styles.titleBlock}>
          <div className={styles.titlePage}>
            <h1>{t("headerTitleUserSetting")}</h1>
          </div>
          <div className={styles.titleAction}>
            <NavLink
              to={`/admin/users/${userId}`}
              className={({ isActive }) =>
                `${styles.titleActionButton} ${isActive ? styles.active : ""}`
              }
              end
            >
              {t("headerButtonUserAccount")}
            </NavLink>
            <NavLink
              to={`/admin/users/auth/${userId}`}
              className={({ isActive }) =>
                `${styles.titleActionButton} ${isActive ? styles.active : ""}`
              }
              end
            >
              {t("headerButtonUserDevice")}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
