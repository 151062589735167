export function calculateProductsTop(orders) {
  const now = new Date();
  const lastYear = new Date(now.getFullYear(), now.getMonth() - 11, 1);

  const filteredOrders = Object.values(orders).filter((order) => {
    const orderDate = new Date(order.date);
    return orderDate >= lastYear && orderDate <= now;
  });

  const productsTop = filteredOrders.reduce((acc, order) => {
    const month = order.month;

    order.products.forEach((product) => {
      const productId = product.productId;

      if (!acc[month]) {
        acc[month] = {};
      }

      if (!acc[month][productId]) {
        acc[month][productId] = {
          productId: productId,
          name: product.name,
          total: 0,
          count: 0,
        };
      }

      acc[month][productId].total += product.total;
      acc[month][productId].count += product.count;
    });

    return acc;
  }, {});

  const filteredProductsTop = Object.keys(productsTop).reduce((acc, month) => {
    acc[month] = Object.values(productsTop[month]).filter(
      (product) => product.total > 0 && product.count > 0
    );
    return acc;
  }, {});

  return filteredProductsTop;
}
