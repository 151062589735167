import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../axios";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (returnCount) => {
    const { data } = await axios.get("/admin/users");
    return data;
  }
);

export const fetchUsersCount = createAsyncThunk(
  "users/fetchUsersCount",
  async (returnCount) => {
    const { data } = await axios.get("/admin/users", {
      params: { returnCount }, // передаем returnCount как параметр запроса
    });
    return data;
  }
);

export const updateUsers = createAsyncThunk(
  "user/updateUser",
  async ({ userId, updatedData }) => {
    const { data } = await axios.put(`/admin/user/${userId}/edit`, {
      userId,
      updatedData,
    });
    return data;
  }
);

const initialState = {
  items: [],
  count: 0,
  status: "loading",
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.items = [];
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = "loaded";
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.items = [];
        state.status = "error";
      })
      .addCase(fetchUsersCount.pending, (state) => {
        state.count = [];
        state.status = "loading";
      })
      .addCase(fetchUsersCount.fulfilled, (state, action) => {
        state.count = action.payload;
        state.status = "loaded";
      })
      .addCase(fetchUsersCount.rejected, (state) => {
        state.count = [];
        state.status = "error";
      });
  },
});

export const usersReducer = usersSlice.reducer;
