import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Header from "../../../components/Admin/UserSetting/Header/Header";
import Auth from "../../../components/Admin/UserSetting/Auth/Auth";
import styles from "../../../components/Admin/UserSetting/UserSetting.module.sass";

function UserAuth() {
  const { t } = useTranslation();
  const { userId } = useParams();

  return (
    <main className={styles.userSetting}>
      <div className={styles.containerUserSetting}>
        <Header userId={userId} />
        <div className={styles.settingBlock}>
          <Auth userId={userId} />
        </div>
      </div>
    </main>
  );
}

export default UserAuth;
