import React from "react";
import styles from "./Footer.module.sass";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();

  const contentVariantsBottom = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: (customDelay) => ({
      y: 0,
      rotate: 0,
      opacity: 1,
      transition: {
        delay: customDelay,
        type: "spring",
        bounce: 0.2,
        duration: 1,
      },
    }),
  };

  return (
    <motion.div
      className={styles.footer}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      <div className={styles.wrapper}>
        <motion.div
          className={styles.left}
          variants={contentVariantsBottom}
          custom={0.6}
        >
          <div className={styles.logo}>
            <NavLink to="/" className={styles.link}>
              <i className={styles.vsrap}></i>
            </NavLink>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.copyright}>
            <p>© 2012 - {currentYear} VSRAP Community</p>
          </div>
        </motion.div>
        <motion.div
          className={styles.right}
          variants={contentVariantsBottom}
          custom={0.6}
        >
          <div className={styles.social}>
            <NavLink
              to="https://vk.com/vsrapru"
              rel="noopener noreferrer"
              target="_blank"
              className={styles.link}
            >
              <i className={styles.vk}></i>
            </NavLink>
            <NavLink
              rel="noopener noreferrer"
              className={styles.link}
              to="https://t.me/vsrapru"
              target="_blank"
            >
              <i className={styles.tg}></i>
            </NavLink>
            <NavLink
              rel="noopener noreferrer"
              className={styles.link}
              to="https://www.youtube.com/@vsrapru"
              target="_blank"
            >
              <i className={styles.youtube}></i>
            </NavLink>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.action}>
            <a href="/account/login" className={styles.button}>
              Войти в кабинет
            </a>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Footer;
