import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./Header.module.sass";
import ButtonBack from "../../../ButtonBack/ButtonBack";

function Header({ activeGroup }) {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = React.useState("");

  const handleButtonClick = (role) => {
    setActiveButton(role);
    activeGroup(role);
  };

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <ButtonBack />
        <div className={styles.titleBlock}>
          <div className={styles.titlePage}>
            <h1>{t("headerTitleUsersList")}</h1>
          </div>
          <div className={styles.titleAction}>
            <Link
              to="#"
              onClick={() => handleButtonClick("")}
              className={`${styles.titleActionButton} ${
                activeButton === "" ? styles.active : ""
              }`}
            >
              {t("headerButtonUsersAll")}
            </Link>
            <Link
              to="#"
              onClick={() => handleButtonClick("Admin")}
              className={`${styles.titleActionButton} ${
                activeButton === "Admin" ? styles.active : ""
              }`}
            >
              {t("headerButtonUsersAdmin")}
            </Link>
            <Link
              to="#"
              onClick={() => handleButtonClick("Redactor")}
              className={`${styles.titleActionButton} ${
                activeButton === "Redactor" ? styles.active : ""
              }`}
            >
              {t("headerButtonUsersRedactor")}
            </Link>
            <Link
              to="#"
              onClick={() => handleButtonClick("Artist")}
              className={`${styles.titleActionButton} ${
                activeButton === "Artist" ? styles.active : ""
              }`}
            >
              {t("headerButtonUsersArtist")}
            </Link>
            {/*<Link*/}
            {/*  to="#"*/}
            {/*  onClick={() => handleButtonClick("User")}*/}
            {/*  className={`${styles.titleActionButton} ${*/}
            {/*    activeButton === "User" ? styles.active : ""*/}
            {/*  }`}*/}
            {/*>*/}
            {/*  {t("headerButtonUsersUser")}*/}
            {/*</Link>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
