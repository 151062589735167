import React from "react";
import { useSelector } from "react-redux";

const YearTotal = ({ selectedPeriod }) => {
  const total = useSelector((state) => {
    if (state.sales.status === "loading") {
      return 0;
    }
    if (state.sales.status === "loaded") {
      const filteredRevenue = Object.values(state.sales.yearTotal).filter(
        (revenue) => revenue.year === `${selectedPeriod}`.slice(0, 7)
      );
      return filteredRevenue.length > 0 ? filteredRevenue[0].total : 0;
    }

    return 0;
  });

  return total.toLocaleString();
};

export default YearTotal;
