import React from "react";
import moment from "moment";
import "moment/locale/ru";
import { useTranslation } from "react-i18next";

const LangIdentificator = () => {
  const { i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem("selectedLanguage") || "ru";

  React.useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    moment.locale(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return null;
};

export default LangIdentificator;
