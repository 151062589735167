export function calculatedMonthPercentOffline(orders) {
  const now = new Date();
  const lastYear = new Date(now.getFullYear(), now.getMonth() - 11, 1);

  const filteredOrders = Object.values(orders).filter((order) => {
    const orderDate = new Date(order.date);
    return orderDate >= lastYear && orderDate <= now;
  });

  const monthOfflineSales = filteredOrders.reduce((acc, order) => {
    if (order.orderID.startsWith("Vozv")) {
      return acc;
    }

    if (!acc[order.month]) {
      acc[order.month] = {
        month: order.month,
        totalOrders: 0,
        offlineCount: 0,
        onlineCount: 0,
        onlineToOfflineRatio: 0,
      };
    }

    acc[order.month].totalOrders += 1;

    if (isNaN(order.orderID)) {
      acc[order.month].offlineCount += 1;
    } else {
      acc[order.month].onlineCount += 1;
    }

    return acc;
  }, {});

  const monthPercentOffline = Object.values(monthOfflineSales).map(
    (monthData) => {
      const { offlineCount, totalOrders } = monthData;

      monthData.onlineToOfflineRatio =
        totalOrders > 0 ? Math.round((offlineCount / totalOrders) * 100) : 0;

      return monthData;
    }
  );

  return monthPercentOffline;
}
