import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { logout } from "../../../redux/slises/Auth/auth";
import fetchUserInfo from "../../User/UserData";

import styles from "./Account.module.sass";

function Login({ userName, userRoles, userAvatar, handleLinkClick }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [roles, setRoles] = React.useState(userRoles);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const roleTranslations = {
    Admin: t("role_admin"),
    Redactor: t("role_editor"),
    Artist: t("role_artist"),
  };

  return (
    <>
      <NavLink
        to="/user/edit"
        className={({ isActive }) =>
          `${isActive ? styles.active : ""} ${styles.accountLink}`
        }
        onClick={handleLinkClick}
      >
        <div className={styles.account}>
          <div className={styles.accountAvatar}>
            <img
              src={userAvatar}
              srcSet={`${userAvatar} 1x, ${userAvatar} 2x`}
              height="40"
              width="40"
              decoding="async"
              fetchpriority="high"
              alt={userName}
            />
            <div className={styles.accountSetting}>
              <i className={styles.iconSetting}></i>
            </div>
          </div>
          <div className={styles.accountInfo}>
            <div className={styles.accountName}>{userName}</div>
            <p className={styles.accountGroup}>{roleTranslations[roles]}</p>
          </div>
        </div>
      </NavLink>
    </>
  );
}

export default fetchUserInfo(Login);
