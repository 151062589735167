import React from "react";
import axios from "../../../../axios";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import ModalDelete from "../../../Modal/DeleteAuth/ModalDelete";
import styles from "../Auth/Auth.module.sass";
import style from "../../../HotToast/Hottoast.module.sass";

import fetchAdminUserAuth from "../UserAuthData";

function Auth({ userId, userAuthList }) {
  const { t } = useTranslation();

  const [isModalDeleteOpen, setModalDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  const handleOpenModal = (id) => {
    setDeleteId(id); // Сохраняем переданный ID
    setModalDeleteOpen(true); // Открываем модальное окно
  };
  const handleCloseModal = () => setModalDeleteOpen(false);

  const handleConfirm = async () => {
    setModalDeleteOpen(false);
    try {
      await axios.delete(`/admin/user/auth/delete/${deleteId}`);
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconSuccess}></div>
          <div className={style.toastTitle}>
            {t("titleToastSettingSuccess")}
            <p>{t("descToastSettingSuccess")}</p>
          </div>
        </div>
      );
      window.location.reload();
    } catch (error) {
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastAccountDeleteError")}
            <p>{t("descToastAccountDeleteError")}</p>
          </div>
        </div>
      );
    }
  };

  const handleCancel = () => {
    setModalDeleteOpen(false);
  };

  async function handleFormSubmit(e) {}

  return (
    <div className={styles.authSetting}>
      <Toaster position="bottom-right" reverseOrder={false} />
      <ModalDelete
        isOpen={isModalDeleteOpen}
        onRequestClose={handleCloseModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <div className={styles.authSettingItem}>
        <div className={styles.authSettingName}>
          <h3>{t("headerTitleUserSettingAuth")}</h3>
        </div>
        <div className={styles.authFormItemGroup}>
          {userAuthList.length === 0 ? (
            <div className={styles.info}>
              Пользователь ни разу не авторизовался
            </div>
          ) : (
            userAuthList.map((auth) => (
              <div key={auth._id} className={styles.authFormItem}>
                <div className={styles.authFormItemInput}>
                  {auth.browser ? (
                    <div className={styles.authFormItemTitle}>
                      {auth.browser}
                    </div>
                  ) : (
                    <div className={styles.authFormItemTitle}>
                      Нет информации
                    </div>
                  )}
                  <div className={styles.itemInfoGroup}>
                    {auth.timeZone ? (
                      <div className={styles.itemInfo}>{auth.timeZone}</div>
                    ) : (
                      <div className={styles.itemInfo}>-</div>
                    )}
                    {auth.userIp ? (
                      <div className={styles.itemInfo}>{auth.userIp}</div>
                    ) : (
                      <div className={styles.itemInfo}>-</div>
                    )}
                    {auth.createdAt ? (
                      <div className={styles.itemInfo}>
                        {new Date(auth.createdAt).toLocaleDateString("ru-RU")}
                      </div>
                    ) : (
                      <div className={styles.itemInfo}>-</div>
                    )}
                    {auth.deviceId ? (
                      <div className={styles.itemInfoId}>{auth.deviceId}</div>
                    ) : (
                      <div className={styles.itemInfoId}>-</div>
                    )}
                  </div>
                  <div
                    className={styles.buttonDeleteAuth}
                    onClick={() => handleOpenModal(auth._id)}
                  >
                    <i className={styles.iconClose}></i>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default fetchAdminUserAuth(Auth);
