import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectIsAdmin } from "../../../redux/slises/Auth/auth";
import { fetchUsersCount } from "../../../redux/slises/Admin/users";

import styles from "./Nav.module.sass";

function Nav({ handleLinkClick }) {
  const { t } = useTranslation();
  const isAuth = useSelector(selectIsAdmin);
  const [isOpenStatistic, setIsOpenStatistic] = React.useState(true);
  const [isOpenPageboy, setIsOpenPageboy] = React.useState(true);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUsersCount(true));
  }, []);

  const usersCount = useSelector((state) => state.users.count);

  React.useEffect(() => {
    const savedStatistic = localStorage.getItem("sidebarMenuStatistic");
    if (savedStatistic) {
      setIsOpenStatistic(JSON.parse(savedStatistic));
    }
    const savedPageboy = localStorage.getItem("sidebarMenuPageboy");
    if (savedPageboy) {
      setIsOpenPageboy(JSON.parse(savedPageboy));
    }
  }, []);

  const toggleMenuSatistic = () => {
    setIsOpenStatistic(!isOpenStatistic);
    localStorage.setItem(
      "sidebarMenuStatistic",
      JSON.stringify(!isOpenStatistic)
    );
  };

  const toggleMenuPageboy = () => {
    setIsOpenPageboy(!isOpenPageboy);
    localStorage.setItem("sidebarMenuPageboy", JSON.stringify(!isOpenPageboy));
  };

  return (
    <nav className={styles.mainMenu}>
      <div
        className={`${styles.menuGroup} ${
          isOpenStatistic ? styles.open : styles.close
        }`}
      >
        <div className={styles.menuTitle} onClick={toggleMenuSatistic}>
          <div className={styles.menuTitleLeft}>
            <div className={styles.menuIcon}>
              <i className={styles.iconSales}></i>
            </div>
            <span className={styles.button}>{t("sidebarNavTitleSales")}</span>
          </div>
          <div className={styles.rollBtn}>
            <i className={styles.arrowRoll}></i>
          </div>
        </div>
        <div className={styles.menuItem}>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? styles.active : "")}
            onClick={handleLinkClick}
          >
            <div className={styles.menuIcon}>
              <i className={styles.iconStatistic}></i>
            </div>
            <span className={styles.button}>{t("sidebarNavItemStats")}</span>
          </NavLink>
        </div>
        <div className={styles.menuItem}>
          <NavLink
            to="/top"
            className={({ isActive }) => (isActive ? styles.active : "")}
            onClick={handleLinkClick}
          >
            <div className={styles.menuIcon}>
              <i className={styles.iconTop}></i>
            </div>
            <span className={styles.button}>{t("sidebarNavItemTopSales")}</span>
          </NavLink>
        </div>
        <div className={styles.menuItem}>
          <NavLink
            to="/orders"
            className={({ isActive }) => (isActive ? styles.active : "")}
            onClick={handleLinkClick}
          >
            <div className={styles.menuIcon}>
              <i className={styles.iconOldOrder}></i>
            </div>
            <span className={styles.button}>
              {t("sidebarNavItemRecentOrders")}
            </span>
          </NavLink>
        </div>
      </div>
      {(isAuth === "Admin" || isAuth === "Redactor") && (
        <>
          <div
            className={`${styles.menuGroup} ${
              isOpenPageboy ? styles.open : styles.close
            }`}
          >
            <div className={styles.menuTitle} onClick={toggleMenuPageboy}>
              <div className={styles.menuTitleLeft}>
                <div className={styles.menuIcon}>
                  <i className={styles.iconPageBoy}></i>
                </div>
                <span className={styles.button}>
                  {t("sidebarNavTitlePageBoy")}
                </span>
              </div>
              <div className={styles.rollBtn}>
                <i className={styles.arrowRoll}></i>
              </div>
            </div>
            <div className={`${styles.menuItem} ${styles.disabled}`}>
              <NavLink to="/add-page">
                <div className={styles.menuIcon}>
                  <i className={styles.iconAddPage}></i>
                </div>
                <span className={styles.button}>
                  {t("sidebarNavItemAddPage")}
                </span>
              </NavLink>
            </div>
            <div className={`${styles.menuItem} ${styles.disabled}`}>
              <NavLink to="/pages">
                <div className={styles.menuIcon}>
                  <i className={styles.iconPages}></i>
                </div>
                <span className={styles.button}>
                  {t("sidebarNavItemPages")}
                </span>
              </NavLink>
            </div>
            <div className={`${styles.menuItem} ${styles.disabled}`}>
              <NavLink to="/posts">
                <div className={styles.menuIcon}>
                  <i className={styles.iconPosts}></i>
                </div>
                <span className={styles.button}>
                  {t("sidebarNavItemPosts")}
                </span>
              </NavLink>
            </div>
          </div>
        </>
      )}
      {(isAuth === "Admin" || isAuth === "Redactor") && (
        <>
          <div className={`${styles.menuGroup} ${styles.last}`}>
            <div className={styles.menuItem}>
              <NavLink
                to="/admin/users"
                className={({ isActive }) => (isActive ? styles.active : "")}
                onClick={handleLinkClick}
              >
                <div className={styles.menuIcon}>
                  <i className={styles.iconUsers}></i>
                </div>
                <span className={styles.button}>
                  {t("sidebarNavItemUsers")}
                </span>
              </NavLink>
              <div className={styles.sticker}>{usersCount}</div>
            </div>
            {isAuth === "Admin" && (
              <div className={styles.menuItem}>
                <NavLink
                  to="/admin/setting"
                  className={({ isActive }) => (isActive ? styles.active : "")}
                  onClick={handleLinkClick}
                >
                  <div className={styles.menuIcon}>
                    <i className={styles.iconSetting}></i>
                  </div>
                  <span className={styles.button}>
                    {t("sidebarNavItemSettings")}
                  </span>
                </NavLink>
              </div>
            )}
          </div>
        </>
      )}
    </nav>
  );
}

export default Nav;
