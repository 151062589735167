import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import AnimateBg from "../../components/Sign/Bg/Bg";
import { useTranslation } from "react-i18next";

import {
  clearErrorAfterTime,
  clearErrorOnNavigation,
  password,
  selectError,
} from "../../redux/slises/Auth/auth";

import styles from "../../components/Sign/Password/Password.module.sass";
import style from "../../components/HotToast/Hottoast.module.sass";

function Password(props) {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onBlur",
  });

  React.useEffect(() => {
    if (error) {
      dispatch(clearErrorAfterTime());
      if (error === "Письмо уже отправлено в течение последних 5 минут!") {
        toast.custom(
          <div className={style.toastHot}>
            <div className={style.toastIconError}></div>
            <div className={style.toastTitle}>
              Письмо не отправлено
              <p>Письмо отправляется один раз в 5 минут</p>
            </div>
          </div>
        );
      } else if (error === "Ссылка для сброса пароля не отправлена!") {
        toast.custom(
          <div className={style.toastHot}>
            <div className={style.toastIconSuccess}></div>
            <div className={style.toastTitle}>
              Письмо успешно отправлено
              <p>В письме ссылка на восстановление пароля</p>
            </div>
          </div>
        );
      } else if (error === "Ссылка для сброса пароля успешно отправлена!") {
        toast.custom(
          <div className={style.toastHot}>
            <div className={style.toastIconSuccess}></div>
            <div className={style.toastTitle}>
              Письмо успешно отправлено
              <p>В письме ссылка на восстановление пароля</p>
            </div>
          </div>
        );
      } else {
        toast.custom(
          <div className={style.toastHot}>
            <div className={style.toastIconError}></div>
            <div className={style.toastTitle}>
              Письмо не отправлено
              <p>Введите корректный адрес электронной почты</p>
            </div>
          </div>
        );
      }
    }
  }, [error, dispatch]);

  const handleNavigation = () => {
    dispatch(clearErrorOnNavigation());
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const email = e.target.email.value.toLowerCase();

    dispatch(password(email));
  };
  return (
    <div className={styles.sign}>
      <Toaster position="bottom-right" reverseOrder={false} />
      <section className={styles.authBlock}>
        <div className={styles.signBlock}>
          <div className={styles.signLogo}>
            <span>ONLY FOR</span>
            <a href="/" className="">
              <i className={styles.iconLogo}></i>
            </a>
          </div>
          <div className={styles.signFormBlock}>
            <div className={styles.signFormTitle}>
              <h1>Восстановление пароля</h1>
            </div>
            <form
              action="#"
              className={styles.signForm}
              onSubmit={handleResetPassword}
            >
              <label className={styles.labelForm}>
                <div className={styles.iconInput}>
                  <i className={styles.iconEmail}></i>
                </div>
                <input
                  className={styles.inputForm}
                  type="email"
                  value={email}
                  placeholder="Email"
                  {...register("email", {
                    required: t("error18"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("error19"),
                    },
                  })}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="hidden">
                  {errors?.firstName?.email &&
                    toast.error(errors?.email?.message, {
                      toastId: "email-error",
                    })}
                </div>
              </label>
              <button type="submit" className={styles.buttonForm}>
                Восстановить пароль
              </button>
            </form>
            <div className={styles.linkFormRegister}>
              <span>Вспомнили пароль?</span>
              <a
                href="/account/login"
                onClick={handleNavigation}
                className={styles.link}
              >
                Войти
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-block">
        <AnimateBg />
      </section>
    </div>
  );
}

export default Password;
