import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "./Skeleton";
import fetchUserInfo from "../../../User/UserData";
import fetchSettingOptions from "../../../Admin/Setting/SettingData/SettingData";
import OrderBlock from "./Item/OrderItem";
import { useTranslation } from "react-i18next";

import { fetchOrders } from "../../../../redux/slises/Sales/orders";
import { clearProduct } from "../../../../redux/slises/Ecwid/ecwid";

import styles from "./LastOrder.module.sass";

const LastOrder = ({ selectedPeriod, selectedCollection, userToken }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [artistToken, setArtistToken] = React.useState(userToken);
  const [visibleOrders, setVisibleOrders] = React.useState(15);

  React.useEffect(() => {
    setVisibleOrders(15);
    dispatch(clearProduct());
  }, [selectedPeriod]);

  React.useEffect(() => {
    setArtistToken(selectedCollection ? selectedCollection : userToken);
    dispatch(clearProduct());
  }, [selectedCollection]);

  React.useEffect(() => {
    dispatch(fetchOrders(artistToken));
    setVisibleOrders(15);
  }, [artistToken]);

  const sales = useSelector((state) => {
    if (state.sales.status === "loading") {
      return [...new Array(5)].map((_, index) => <Skeleton key={index} />);
    }
    if (state.sales.status === "error") {
      return <p className={styles.info}>{t("orderListTitleNon")}</p>;
    }
    if (state.sales.status === "loaded" && state.sales.orders) {
      const orders = Object.values(
        state.sales.orders[artistToken] || {}
      ).filter((order) => order.month === `${selectedPeriod}`);

      if (orders.length === 0) {
        return <p className={styles.info}>{t("orderListTitleNon")}</p>;
      }

      orders.sort((a, b) => new Date(b.date) - new Date(a.date));

      return (
        <>
          {orders.slice(0, Math.min(visibleOrders, 5)).map((order) => (
            <OrderBlock
              key={order.orderID}
              orderID={order.orderID}
              total={order.total}
              date={order.date}
              count={order.count}
              products={order.products}
              productsId={order.products.productId}
              period={selectedPeriod}
              artist={selectedCollection}
            />
          ))}
        </>
      );
    }

    return [];
  });

  return (
    <>
      <div className={styles.orderList}>{sales}</div>
    </>
  );
};

export default fetchSettingOptions(fetchUserInfo(LastOrder));
