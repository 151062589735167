import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ruJSON from "./Ru";
import enJSON from "./En";

const resources = {
  ru: { translation: ruJSON },
  en: { translation: enJSON },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // detection: {
    //   order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
    //   lookupQuerystring: "lng",
    //   lookupCookie: "selectedLanguage",
    //   lookupLocalStorage: "selectedLanguage",
    //   caches: ["localStorage"],
    // },
    debug: false,
    keySeparator: false,
    lng: "ru",
    fallbackLng: "ru",
    react: {
      wait: true,
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
