import React from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "../../../axios";
import fetchSettingOptions from "../../../components/Admin/Setting/SettingData/SettingData";
import { useTranslation } from "react-i18next";

import styles from "../../../components/Admin/Setting/Setting.module.sass";
import Header from "../../../components/Admin/Setting/Header/Header";
import style from "../../../components/HotToast/Hottoast.module.sass";

function Sales({
  settingPercentLow,
  settingPercentMiddle,
  settingPercentHigh,
  settingTotalLow,
  settingTotalMiddle,
}) {
  const { t } = useTranslation();
  const [percentLow, setPercentLow] = React.useState(settingPercentLow);
  const [percentMiddle, setPercentMiddle] =
    React.useState(settingPercentMiddle);
  const [percentHigh, setPercentHigh] = React.useState(settingPercentHigh);
  const [totalLow, setTotalLow] = React.useState(settingTotalLow);
  const [totalMiddle, setTotalMiddle] = React.useState(settingTotalMiddle);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Проверить, были ли внесены изменения в форму
    if (
      percentLow === settingPercentLow &&
      percentMiddle === settingPercentMiddle &&
      percentHigh === settingPercentHigh &&
      totalLow === settingTotalLow &&
      totalMiddle === settingTotalMiddle
    ) {
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastSettingInfo")}
            <p>{t("descToastSettingInfo")}</p>
          </div>
        </div>
      );
      return;
    }

    const updatedFormData = {
      percent_low: percentLow,
      percent_middle: percentMiddle,
      percent_high: percentHigh,
      total_low: totalLow,
      total_middle: totalMiddle,
    };

    try {
      await axios.put(`/admin/setting`, {
        updatedData: updatedFormData,
      });
      // toast.custom(
      //   <div className={style.toastHot}>
      //     <div className={style.toastIconSuccess}></div>
      //     <div className={style.toastTitle}>
      //       {t("titleToastSettingSuccess")}
      //       <p>{t("descToastSettingSuccess")}</p>
      //     </div>
      //   </div>
      // );
      window.location.reload();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.custom(
          <div className={style.toastHot}>
            <div className={style.toastIconError}></div>
            <div className={style.toastTitle}>
              {t("titleToastSettingError")}
              <p>{t("descToastSettingError")}</p>
            </div>
          </div>
        );
      }
    }
  }

  React.useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  return (
    <main className={styles.platformSetting}>
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className={styles.containerPlatformSetting}>
        <Header />
        <div className={styles.settingBlock}>
          <div className={styles.platformSetting}>
            <form
              onSubmit={handleFormSubmit}
              className={styles.platformSettingGroup}
            >
              <div className={styles.platformSettingItem}>
                <div className={styles.platformSettingName}>
                  <h3>{t("headerTitleUserSettingSales")}</h3>
                </div>
                <div className={styles.platformFormItemGroup}>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelSalesSettingPercentLow")}
                      <p>{t("labelDescSalesSettingPercentLow")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={percentLow}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformPercent")}
                        onChange={(e) => setPercentLow(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelSalesSettingPercentMiddle")}
                      <p>{t("labelDescSalesSettingPercentMiddle")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={percentMiddle}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformPercentMiddle")}
                        onChange={(e) => setPercentMiddle(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelSalesSettingPercentHigh")}
                      <p>{t("labelDescSalesSettingPercentHigh")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={percentHigh}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformPercentHigh")}
                        onChange={(e) => setPercentHigh(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelSalesSettingTotalLow")}
                      <p>{t("labelDescSalesSettingTotalLow")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={totalLow}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformTotalLow")}
                        onChange={(e) => setTotalLow(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelSalesSettingTotalHigh")}
                      <p>{t("labelDescSalesSettingTotalHigh")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={totalMiddle}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformTotalHigh")}
                        onChange={(e) => setTotalMiddle(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.buttonBlock}>
                  <button type="submit" className={styles.button}>
                    {t("buttonSaveSettingUser")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default fetchSettingOptions(Sales);
