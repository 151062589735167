import React from "react";
import { Link, useNavigate } from "react-router-dom";

import styles from "./ButtonBack.module.sass";

function ButtonBack(props) {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Link to="#" onClick={handleGoBack} className={styles.buttonBackBlock}>
      <i className={styles.iconBack}></i>
    </Link>
  );
}

export default ButtonBack;
