import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ButtonSocial.module.sass";

const SocialLoginButtons = () => {
  const { t } = useTranslation();

  const handleSocialLogin = (e, provider) => {
    e.preventDefault();
    const newUrl = `${process.env.REACT_APP_API_URL}/auth/${provider}`;
    window.location.href = newUrl;
  };

  const handleVk = (e) => {
    handleSocialLogin(e, "vk");
  };

  const handleGoogle = (e) => {
    handleSocialLogin(e, "google");
  };

  // const handleFb = (e) => {
  //   handleSocialLogin(e, "fb");
  // };

  return (
    <div className={styles.signSoc}>
      <div className={styles.separatorBlock}>
        <span>или</span>
      </div>
      <div className={styles.signSocBtn}>
        <button onClick={handleVk} className={styles.buttonSoc}>
          <i className={styles.iconVk}></i>
          <span>Вконтакте</span>
        </button>
        {/*<button onClick={handleFb} className={styles.buttonSoc}>*/}
        {/*  <i className="icon-fb"></i>*/}
        {/*</button>*/}
        <button onClick={handleGoogle} className={styles.buttonSoc}>
          <i className={styles.iconGoogle}></i>
          <span>Google</span>
        </button>
      </div>
    </div>
  );
};

export default SocialLoginButtons;
