import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import fetchUserInfo from "../../../components/User/UserData";

import Header from "../../../components/Merch/Statistic/Header/Header";
import styles from "../../../components/Merch/LastOrders/LastOrders.module.sass";
import LastOrder from "../../../components/Merch/LastOrders/LastOrder/LastOrder";

function LastOrders({ userRoles, userCollection }) {
  const { t } = useTranslation();
  const currentYear = moment().year();
  const currentMonth = moment().format("MM");
  const currentMonthName = moment().format("MMMM");
  const сurrentDate = currentYear + "-" + currentMonth;

  const [selectedPeriod, setSelectedPeriod] = React.useState(сurrentDate);
  const [selectedCollection, setSelectedCollection] = React.useState();
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonthName);
  const [selectedYear, setSelectedYear] = React.useState(currentYear);

  const blockRef = React.useRef(null);

  const selectMonth = (selectedMonth) => {
    setSelectedMonth(selectedMonth);
  };

  const selectYear = (selectedYear) => {
    setSelectedYear(selectedYear);
  };

  const selectData = (selectedPeriod) => {
    setSelectedPeriod(selectedPeriod);
  };

  const handleCollectionSelect = (artist) => {
    setSelectedCollection(artist);
    setSelectedPeriod(сurrentDate);
  };

  return (
    <main className={styles.merch}>
      <div className={styles.containerMerch}>
        <Header />
        <div className={styles.content}>
          <div className={styles.contentOrders}>
            <div className={styles.contentOrdersBlock}>
              <div className={styles.contentOrdersBlockTitle}>
                <h2>{t("statisticTitleBlockOrder")}</h2>
                <span className={styles.contentOrdersBlockPeriod}>
                  {selectedMonth} {selectedYear}
                </span>
              </div>
              <div className={styles.orders}>
                <LastOrder
                  selectedPeriod={selectedPeriod}
                  selectedCollection={selectedCollection}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default fetchUserInfo(LastOrders);
