import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { usersReducer } from "./slises/Admin/users";
import { authReducer } from "./slises/Auth/auth";
import { ecwidReducer } from "./slises/Ecwid/ecwid";
import { adminReducer } from "./slises/Admin/admin";
import { userReducer } from "./slises/User/user";
import { salesReducer } from "./slises/Sales/orders";

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  ecwid: ecwidReducer,
  user: userReducer,
  admin: adminReducer,
  sales: salesReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
