import React from "react";
import { useSelector } from "react-redux";

const MonthTotalAbb = ({ selectedPeriod }) => {
  const total = useSelector((state) => {
    if (state.sales.status === "loading") {
      return "- - -";
    }
    if (state.sales.status === "loaded") {
      const filteredRevenue = Object.values(state.sales.monthTotal).filter(
        (revenue) => revenue.month === `${selectedPeriod}`
      );
      const revenue = filteredRevenue.length > 0 ? filteredRevenue[0].total : 0;

      if (revenue >= 1000000000) {
        return `${(revenue / 1000000000).toFixed(1)}B`;
      } else if (revenue >= 1000000) {
        return `${(revenue / 1000000).toFixed(1)}M`;
      } else if (revenue >= 1000) {
        return `${Math.floor(revenue / 1000)}K`;
      } else {
        return revenue.toString();
      }
    }

    return 0;
  });

  return total;
};

export default MonthTotalAbb;
