import React from "react";
import { Link } from "react-router-dom";

import styles from "./UsersList.module.sass";

function UserItem({ userId, userName, userAvatar, userRoles }) {
  return (
    <div className={styles.usersBlockItem} key={userName}>
      <div className={styles.userInfo}>
        <div className={styles.userAvatar}>
          <img src={userAvatar} height="500" width="500" alt={userName} />
        </div>
        <div className={styles.userName}>
          <Link to={`/admin/users/${userId}`}>
            <h3>{userName}</h3>
          </Link>
          <p>{userRoles}</p>
        </div>
      </div>
      <div className={styles.usersBg}>
        <img src={userAvatar} height="130" width="130" />
      </div>
    </div>
  );
}

export default UserItem;
