import React from "react";
import YearTotal from "../Numbers/YearTotal";
import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";

import styles from "./TotalYear.module.sass";

function TotalYear({ currentData, activeData, oldData }) {
  const { t } = useTranslation();
  const [isTotalYear, setIsTotalYear] = React.useState(true);

  const handleTotalYearClick = () => {
    setIsTotalYear(!isTotalYear);
  };

  const buttonYearClass = isTotalYear ? styles.current : styles.old;

  return (
    <div className={styles.statsYearBlock}>
      <div className={styles.statsYearTitle}>
        <h3>{t("statisticTitleBlockYear")}</h3>
      </div>
      {isTotalYear ? (
        <div className={styles.statsYearTotal}>
          {activeData ? <YearTotal selectedPeriod={currentData} /> : 0}
          <p className={styles.totalCurrent}>₽</p>
        </div>
      ) : (
        <div className={styles.statsYearTotal}>
          {activeData ? <YearTotal selectedPeriod={oldData} /> : 0}
          <p className={styles.totalCurrent}>₽</p>
        </div>
      )}
      <div className={`${styles.buttonYear} ${buttonYearClass}`}>
        <div
          className={`${styles.button} ${styles.buttonCurrent}`}
          onClick={handleTotalYearClick}
        >
          {currentData}
        </div>
        <div
          className={`${styles.button} ${styles.buttonOld}`}
          onClick={handleTotalYearClick}
        >
          {oldData}
        </div>
      </div>
    </div>
  );
}

export default TotalYear;
