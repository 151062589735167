export function calculatedMonthTotal(orders) {
  const now = new Date();
  const lastYear = new Date(now.getFullYear(), now.getMonth() - 11, 1);

  const filteredOrders = Object.values(orders).filter((order) => {
    const orderDate = new Date(order.date);
    return orderDate >= lastYear && orderDate <= now;
  });

  const monthTotal = filteredOrders.reduce((acc, order) => {
    if (!acc[order.month]) {
      acc[order.month] = {
        month: 0,
        total: 0,
      };
    }
    acc[order.month].month = order.month;
    acc[order.month].total += order.total;
    return acc;
  }, {});

  return monthTotal;
}
