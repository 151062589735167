import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import SocialLoginButtons from "../../components/Sign/ButtonSocial/ButtonSocial";
// import AnimateBg from "../../components/Sign/Bg/Bg";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useTranslation } from "react-i18next";
import {
  clearErrorAfterTime,
  clearErrorOnNavigation,
  registration,
  selectError,
} from "../../redux/slises/Auth/auth";

import styles from "../../components/Sign/SignUp/SignUp.module.sass";
import style from "../../components/HotToast/Hottoast.module.sass";

function SignUp(props) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  React.useEffect(() => {
    if (error) {
      dispatch(clearErrorAfterTime());
      toast.custom(
        <div className={style.toastHot}>
          <div className={style.toastIconError}></div>
          <div className={style.toastTitle}>
            {t("titleToastSignUpgError")}
            <p>{t("descToastSignUpgError")}</p>
          </div>
        </div>
      );
    }
  }, [error, dispatch]);

  const handleNavigation = () => {
    dispatch(clearErrorOnNavigation());
  };

  const onSubmit = async (values) => {
    const deviceId = await getDeviceId();
    values.deviceId = deviceId;

    const data = await dispatch(registration(values));

    if (!data.payload) {
      return;
    }

    if ("accessToken" in data.payload) {
      window.localStorage.setItem("token", data.payload.accessToken);
      window.location.href = "/";
    }
  };

  const getDeviceId = async () => {
    try {
      // Инициализация объекта FingerprintJS
      const fp = await FingerprintJS.load();

      // Определение уникального идентификатора устройства
      const result = await fp.get();
      const visitorId = result.visitorId;

      return visitorId;
    } catch (error) {
      console.error(t("error10"));
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={styles.sign}>
      <Toaster position="bottom-right" reverseOrder={false} />
      <section className={styles.authBlock}>
        <div className={styles.signBlock}>
          <div className={styles.signLogo}>
            <span>ONLY FOR</span>
            <a href="/" className="">
              <i className={styles.iconLogo}></i>
            </a>
          </div>
          <div className={styles.signFormBlock}>
            <div className={styles.signFormTitle}>
              <h1>Регистрация</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.signForm}>
              <div className={styles.labelBlind}>
                <label className={styles.labelForm}>
                  <div className={styles.iconInput}>
                    <i className={styles.iconName}></i>
                  </div>
                  <input
                    className={styles.inputForm}
                    type="text"
                    placeholder="Имя"
                    {...register("firstName")}
                  />
                </label>
                <label className={styles.labelForm}>
                  <div className={styles.iconInput}>
                    <i className={styles.iconName}></i>
                  </div>
                  <input
                    className={styles.inputForm}
                    type="text"
                    placeholder="Фамилия"
                    {...register("lastName")}
                  />
                </label>
              </div>
              <label className={styles.labelForm}>
                <div className={styles.iconInput}>
                  <i className={styles.iconEmail}></i>
                </div>
                <input
                  className={styles.inputForm}
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                />
              </label>
              <label className={styles.labelForm}>
                <div className={styles.iconInput}>
                  <i className={styles.iconPassword}></i>
                </div>
                <input
                  className={styles.inputForm}
                  type={showPassword ? "text" : "password"}
                  autoComplete="on"
                  placeholder="Пароль"
                  {...register("password")}
                />
                <span
                  onClick={handleTogglePassword}
                  className={styles.passwordToggle}
                >
                  {showPassword ? (
                    <i className={styles.iconEyeClose}></i>
                  ) : (
                    <i className={styles.iconEye}></i>
                  )}
                </span>
              </label>
              <label className={styles.labelForm}>
                <div className={styles.iconInput}>
                  <i className={styles.iconPassword}></i>
                </div>
                <input
                  className={styles.inputForm}
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="on"
                  placeholder="Повторите пароль"
                  {...register("confirmPassword")}
                />
                <span
                  onClick={handleToggleConfirmPassword}
                  className={styles.passwordToggle}
                >
                  {showConfirmPassword ? (
                    <i className={styles.iconEyeClose}></i>
                  ) : (
                    <i className={styles.iconEye}></i>
                  )}
                </span>
              </label>
              {error && (
                <div className="info-form">
                  <p className="caption-2">{error}</p>
                </div>
              )}
              <button type="submit" className={styles.buttonForm}>
                Зарегистрировать аккаунт
              </button>
            </form>
          </div>
          <SocialLoginButtons />
          <div className={styles.linkFormRegister}>
            <span>Есть аккаунт?</span>
            <a
              href="/account/login"
              onClick={handleNavigation}
              className={styles.link}
            >
              Войти
            </a>
          </div>
        </div>
      </section>
      <section className={styles.bgBlock}>
        <div id="bg-animate" className={styles.bgActive}></div>
      </section>
      {/*<AnimateBg />*/}
    </div>
  );
}

export default SignUp;
