import React from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Skeleton from "../../../components/Admin/UsersList/Skeleton";
import UserItem from "../../../components/Admin/UsersList/UsersList";
import { fetchUsers } from "../../../redux/slises/Admin/users";

import styles from "../../../components/Admin/UsersList/UsersList.module.sass";
import Header from "../../../components/Admin/UsersList/Header/Header";

function UsersList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [search, setSearch] = React.useState("");
  const [activeButton, setActiveButton] = React.useState("");

  const skeleton = [...new Array(12)].map((_, index) => (
    <Skeleton key={index} />
  ));

  React.useEffect(() => {
    dispatch(fetchUsers());
    toast.dismiss();
  }, []);

  const users = useSelector((state) => state.users.items);

  const filteredUsers = users.filter((user) => {
    if (activeButton && String(user.roles) !== activeButton) {
      return false;
    }
    return !(
      search &&
      !user.lastName.toLowerCase().includes(search.toLowerCase()) &&
      !user.firstName.toLowerCase().includes(search.toLowerCase())
    );
  });

  const status = useSelector((state) => state.users.status);
  const isLoading = status === "loading";
  const isError = status === "error";
  const isLoaded = status === "loaded";
  const isNoResults = filteredUsers.length === 0;

  const roleTranslations = {
    Admin: t("role_admin"),
    Redactor: t("role_editor"),
    Artist: t("role_artist"),
  };

  const activeGroupButton = (role) => {
    setActiveButton(role);
  };

  const handleSearch = (phrase) => {
    setSearch(phrase.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  return (
    <main className={styles.users}>
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className={styles.containerUsers}>
        <Header activeGroup={activeGroupButton} />
        <div className={styles.searchBlock}>
          <div className={styles.searchField}>
            {search && (
              <div onClick={handleClearSearch} className={styles.searchClose}>
                <i className={styles.iconClose}></i>
              </div>
            )}
            <input
              value={search}
              onChange={handleSearch}
              className={styles.searchInput}
              placeholder={t("userlistInputPlaceholder")}
            />
            <div className={styles.searchLens}>
              <i className={styles.iconSearch}></i>
            </div>
          </div>
        </div>
        <div className={styles.usersList}>
          <div className={styles.usersBlock}>
            {isLoading ? (
              <div className={styles.userBlockSkeleton}>{skeleton}</div>
            ) : isError ? (
              <p className={styles.info}>{t("userlistErrorUpload")}</p>
            ) : isNoResults ? (
              <p className={styles.info}>{t("userlistErrorNot")}</p>
            ) : isLoaded ? (
              filteredUsers.map((user) => (
                <UserItem
                  key={user._id}
                  userId={user._id}
                  userName={user.firstName + " " + user.lastName}
                  userAvatar={user.avatarURL}
                  userRoles={roleTranslations[user.roles]}
                  userFirstName={user.firstName}
                  userLastName={user.lastName}
                />
              ))
            ) : (
              <p className={styles.info}>{t("userlistErrorUpload")}</p>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default UsersList;
