import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import fetchUserInfo from "../../components/User/UserData";
import { useTranslation } from "react-i18next";
// import AnimateBg from "../../components/Sign/Bg/Bg";
import {
  clearErrorAfterTime,
  clearErrorOnNavigation,
  logout,
  selectError,
} from "../../redux/slises/Auth/auth";

import styles from "../../components/Sign/Activate/Activate.module.sass";

function Activate({ userEmail }) {
  const { t } = useTranslation();
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (error) {
      dispatch(clearErrorAfterTime());
      if (error === t("error24")) {
        toast.error(t("error25"));
      } else if (error === t("success5")) {
        toast.success(t("success6"));
      } else {
        toast.error(t("success26"));
      }
    }
  }, [error, dispatch]);

  const handleNavigation = () => {
    dispatch(clearErrorOnNavigation());
  };

  const onClickLogout = () => {
    dispatch(logout());
    window.location.href = "/";
  };

  return (
    <div className={styles.activate}>
      <section className={styles.activateBlock}>
        <div className={styles.activateContentBlock}>
          <div className={styles.logo}>
            <span>ONLY FOR</span>
            <a href="/" className="">
              <i className={styles.iconLogo}></i>
            </a>
          </div>
          <div className={styles.title}>
            <h1>Аккаунт не активирован</h1>
          </div>
          <div className={styles.text}>
            <p>
              Ваша учетная запись еще не активирована администрацией платформы.
            </p>
          </div>
          <div className={styles.buttonBlock}>
            <Link
              to="#"
              className={styles.button}
              onClick={() => {
                handleNavigation();
                onClickLogout();
              }}
            >
              Выйти из аккаунта
            </Link>
          </div>
        </div>
      </section>
      <section className={styles.bgBlock}>
        <div id="bg-animate" className={styles.bgActive}></div>
      </section>
      {/*<AnimateBg />*/}
    </div>
  );
}

export default fetchUserInfo(Activate);
