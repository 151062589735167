import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import styles from "../../../components/Admin/Setting/Setting.module.sass";
import Header from "../../../components/Admin/Setting/Header/Header";
import config from "../../../config.json";

function Sales() {
  const { t } = useTranslation();
  const [develop, setDevelop] = React.useState(config.develop);
  const [title, setTitle] = React.useState(config.title);
  const [description, setDescription] = React.useState(config.description);
  const [copyright, setCopyright] = React.useState(config.copyright);
  const [ecwidApiUrl, setEcwidApiUrl] = React.useState(config.ecwidApiUrl);
  const [ecwidShopId, setEcwidShopId] = React.useState(config.ecwidShopId);
  const [ecwidPublicToken, setEcwidPublicToken] = React.useState(
    config.ecwidPublicToken
  );
  const [ecwidSecretToken, setEcwidSecretToken] = React.useState(
    config.ecwidSecretToken
  );
  const [ecwidMainCollectionId, setEcwidMainCollectionId] = React.useState(
    config.ecwidMainCollectionId
  );
  const [showToken, setShowToken] = React.useState(false);

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  async function handleFormSubmit(e) {
    e.preventDefault();

    try {
    } catch (error) {}
  }

  const handleToggleToken = () => {
    setShowToken((prevShowToken) => !prevShowToken);
  };

  return (
    <main className={styles.platformSetting}>
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className={styles.containerPlatformSetting}>
        <Header />
        <div className={styles.settingBlock}>
          <div className={styles.platformSetting}>
            <form
              onSubmit={handleFormSubmit}
              className={styles.platformSettingGroup}
            >
              <div className={styles.platformSettingItem}>
                <div className={styles.platformSettingName}>
                  <h3>{t("headerTitlePlatformSettingAll")}</h3>
                </div>
                <div className={styles.platformFormItemGroup}>
                  <div
                    className={`${styles.platformFormItem} checkbox-wrapper`}
                  >
                    <div className={styles.platformFormItemTitle}>
                      {t("labelPlatformSettingDev")}
                      <p>{t("labelDescPlatformSettingDev")}</p>
                    </div>
                    <label
                      className={`${styles.checkboxWrapper} ${styles.platformFormItemInput}`}
                    >
                      <input
                        type="checkbox"
                        name="extendedOrder"
                        className={styles.checkbox}
                        checked={develop}
                        onChange={(e) => setDevelop(e.target.checked)}
                      />
                      <div className={styles.checkboxSlider}>
                        <div className={styles.checkboxKnob}></div>
                      </div>
                    </label>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelPlatformSettingTitle")}
                      <p>{t("labelDescPlatformSettingTitle")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={title}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformTitle")}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelPlatformSettingDesc")}
                      <p>{t("labelDescPlatformSettingDesc")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={description}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformDesc")}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelPlatformSettingCopyright")}
                      <p>{t("labelDescPlatformSettingCopyright")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={copyright}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformCopyright")}
                        onChange={(e) => setCopyright(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.platformSettingItem}>
                <div className={styles.platformSettingName}>
                  <h3>{t("headerTitleUserSettingEcwid")}</h3>
                </div>
                <div className={styles.platformFormItemGroup}>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelPlatformSettingApi")}
                      <p>{t("labelDescPlatformSettingApi")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={ecwidApiUrl}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformApi")}
                        onChange={(e) => setEcwidApiUrl(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelPlatformSettingShopId")}
                      <p>{t("labelDescPlatformSettingShopId")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={ecwidShopId}
                        className={styles.settingInput}
                        placeholder={t("placeholderUserPercent")}
                        onChange={(e) => setEcwidShopId(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelPlatformSettingPublicToken")}
                      <p>{t("labelDescPlatformSettingPublicToken")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={ecwidPublicToken}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformToken")}
                        onChange={(e) => setEcwidPublicToken(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelPlatformSettingSecretToken")}
                      <p>{t("labelDescPlatformSettingSecretToken")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={ecwidSecretToken}
                        type={showToken ? "text" : "password"}
                        className={styles.settingInput}
                        placeholder={t("placeholderPlatformToken")}
                        onChange={(e) => setEcwidSecretToken(e.target.value)}
                        required
                      />
                      <span
                        onClick={handleToggleToken}
                        className={styles.platformFormItemInputPass}
                      >
                        {showToken ? (
                          <i className={styles.iconEyeClose}></i>
                        ) : (
                          <i className={styles.iconEye}></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className={styles.platformFormItem}>
                    <div className={styles.platformFormItemTitle}>
                      {t("labelPlatformSettingCollectionId")}
                      <p>{t("labelDescPlatformSettingCollectionId")}</p>
                    </div>
                    <div className={styles.platformFormItemInput}>
                      <input
                        value={ecwidMainCollectionId}
                        className={styles.settingInput}
                        placeholder={t("placeholderUserPercent")}
                        onChange={(e) =>
                          setEcwidMainCollectionId(e.target.value)
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.buttonBlock}>
                  <button type="submit" className={styles.button}>
                    {t("buttonSaveSettingUser")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Sales;
