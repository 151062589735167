import React from "react";
import styles from "./Cards.module.sass";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Cards() {
  const [platformButton, setPlatformButton] = React.useState(
    styles.platformButtonMob
  );

  const handlePlatform = (platform) => {
    setPlatformButton(platform);
  };

  const cardVariants = {
    offscreen: {
      y: 50,
    },
    onscreen: (customDelay) => ({
      y: 0,
      rotate: 0,
      transition: {
        delay: customDelay,
        type: "spring",
        bounce: 0.2,
        duration: 1,
      },
    }),
  };

  const contentVariantsTop = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: (customDelay) => ({
      y: 0,
      rotate: 0,
      opacity: 1,
      transition: {
        delay: customDelay,
        type: "spring",
        bounce: 0.2,
        duration: 1,
      },
    }),
  };

  const contentVariantsRight = {
    offscreen: {
      x: 20,
      opacity: 0,
    },
    onscreen: (customDelay) => ({
      x: 0,
      rotate: 0,
      opacity: 1,
      transition: {
        delay: customDelay,
        type: "spring",
        bounce: 0.2,
        duration: 1,
      },
    }),
  };

  const contentVariantsLeft = {
    offscreen: {
      x: -20,
      opacity: 0,
    },
    onscreen: (customDelay) => ({
      x: 0,
      rotate: 0,
      opacity: 1,
      transition: {
        delay: customDelay,
        type: "spring",
        bounce: 0.2,
        duration: 1,
      },
    }),
  };

  const contentVariantsOpacity = {
    offscreen: {
      x: 0,
      opacity: 0,
    },
    onscreen: (customDelay) => ({
      x: 0,
      rotate: 0,
      opacity: 1,
      transition: {
        delay: customDelay,
        type: "spring",
        bounce: 0.2,
        duration: 1,
      },
    }),
  };

  return (
    <div className={styles.cards} initial="offscreen" animate="onscreen">
      <div className={styles.wrapper}>
        <motion.div
          className={styles.header}
          initial="offscreen"
          animate="onscreen"
        >
          <motion.h2
            className={styles.title}
            variants={contentVariantsTop}
            custom={1.1}
          >
            Добро пожаловать в VSRAPBOY
            <span>2.0</span>
          </motion.h2>
          <motion.p
            className={styles.description}
            variants={contentVariantsTop}
            custom={1.1}
          >
            Великая сила в ваших руках
          </motion.p>
        </motion.div>
        <div className={styles.card}>
          <motion.div
            className={`${styles.cardItem} ${styles.cardData}`}
            initial="offscreen"
            whileInView="onscreen"
            variants={contentVariantsTop}
            viewport={{ once: true }}
          >
            <motion.div
              className={styles.cardText}
              variants={contentVariantsTop}
              custom={0.7}
            >
              <div className={styles.cardTitle}>
                <h3>Обновление данных в реальном времени</h3>
              </div>
              <div className={styles.cardDescription}>
                <p>Данные розничных и онлайн продаж поступают мгновенно.</p>
              </div>
            </motion.div>
            <div className={styles.data}>
              <motion.div
                className={styles.floating}
                variants={contentVariantsTop}
                custom={0.6}
              >
                <motion.div
                  className={styles.floatingSecond}
                  variants={contentVariantsRight}
                  custom={0.6}
                ></motion.div>
              </motion.div>
              <motion.div className={styles.images}>
                <img
                  alt="Обновление данных в реальном времени"
                  decoding="async"
                  fetchpriority="high"
                  src="/img/homepage/data/data.svg"
                  srcSet="/img/homepage/data/data.svg 1x, /img/homepage/data/data.svg 2x"
                />
              </motion.div>
            </div>
          </motion.div>
          <motion.div
            className={`${styles.cardItem} ${styles.cardOrder}`}
            initial="offscreen"
            whileInView="onscreen"
            variants={contentVariantsTop}
            viewport={{ once: true }}
          >
            <div className={styles.order}>
              <motion.div
                className={styles.floating}
                variants={contentVariantsLeft}
                custom={0.6}
              ></motion.div>
              <div className={styles.images}>
                <img
                  alt="Актуальный список последних заказов"
                  decoding="async"
                  fetchpriority="high"
                  src="/img/homepage/order/orders.svg"
                  srcSet="/img/homepage/order/orders.svg 1x, /img/homepage/order/orders.svg 2x"
                />
              </div>
            </div>
            <motion.div
              className={styles.cardText}
              variants={contentVariantsTop}
              custom={0.7}
            >
              <div className={styles.cardTitle}>
                <h3>Актуальный список последних заказов</h3>
              </div>
              <div className={styles.cardDescription}>
                <p>
                  В заказах указана вся детальная информация, стоимость, время и
                  количество товаров.
                </p>
              </div>
            </motion.div>
          </motion.div>
          <motion.div
            className={`${styles.cardItem} ${styles.cardProduct}`}
            initial="offscreen"
            whileInView="onscreen"
            variants={contentVariantsTop}
            viewport={{ once: true }}
          >
            <motion.div
              className={styles.cardText}
              variants={contentVariantsTop}
              custom={0.7}
            >
              <div className={styles.cardTitle}>
                <h3>Топ самых продаваемых товаров за месяц</h3>
              </div>
              <div className={styles.cardDescription}>
                <p>
                  Все товары указаны с количеством проданных единиц и суммой
                  выручки по каждой позиции.
                </p>
              </div>
            </motion.div>
            <div className={styles.product}>
              <motion.div
                className={styles.floating}
                variants={contentVariantsRight}
                custom={0.6}
              ></motion.div>
              <div className={styles.images}>
                <img
                  alt="Топ самых продаваемых товаров за месяц"
                  decoding="async"
                  fetchpriority="high"
                  src="/img/homepage/product/product.svg"
                  srcSet="/img/homepage/product/product.svg 1x, /img/homepage/product/product.svg 2x"
                />
              </div>
            </div>
          </motion.div>
          <div className={styles.cardTwo}>
            <motion.div
              className={`${styles.cardItem} ${styles.cardAdaptive}`}
              initial="offscreen"
              whileInView="onscreen"
              variants={contentVariantsTop}
              viewport={{ once: true }}
            >
              <div className={styles.adaptive}>
                <div
                  className={`${styles.images} ${
                    platformButton === styles.platformButtonMob
                      ? "visible-mob"
                      : platformButton === styles.platformButtonPc
                      ? "visible-pc"
                      : ""
                  }`}
                >
                  {platformButton === styles.platformButtonPc && (
                    <motion.div
                      className={styles.pcDashboard}
                      initial="offscreen"
                      whileInView="onscreen"
                      variants={contentVariantsTop}
                      viewport={{ once: true }}
                      custom={0.2}
                    >
                      <i className={styles.pcDashboardHome}></i>
                    </motion.div>
                  )}
                  {platformButton === styles.platformButtonMob && (
                    <motion.div
                      className={styles.mobDashboard}
                      initial="offscreen"
                      whileInView="onscreen"
                      variants={contentVariantsTop}
                      viewport={{ once: true }}
                      custom={0.2}
                    >
                      <motion.i className={styles.mobDashboardHome}></motion.i>
                      <motion.i
                        initial="offscreen"
                        whileInView={{
                          rotate: 22,
                          transition: { delay: 0.6 },
                        }}
                        viewport={{ once: true }}
                        className={styles.mobDashboardProfile}
                      ></motion.i>
                      <motion.i
                        initial="offscreen"
                        whileInView={{
                          rotate: -22,
                          transition: { delay: 0.6 },
                        }}
                        viewport={{ once: true }}
                        className={styles.mobDashboardMenu}
                      ></motion.i>
                    </motion.div>
                  )}
                </div>
              </div>
              <motion.div
                className={styles.header}
                variants={contentVariantsTop}
                custom={0.6}
              >
                <div className={styles.cardText}>
                  <div className={styles.cardTitle}>
                    <h3>
                      Мобильная и <br />
                      десктопная версия
                    </h3>
                  </div>
                  <div className={styles.cardDescription}>
                    <p>
                      Платформа адаптивна к большинству <br />
                      современных устройств и разрешений.
                    </p>
                  </div>
                </div>
                <div className={`${styles.platformButton} ${platformButton}`}>
                  <Link
                    onClick={() => handlePlatform(styles.platformButtonMob)}
                    className={`${styles.platformButtonButton} ${
                      platformButton === styles.platformButtonMob
                        ? styles.platformButtonActive
                        : ""
                    }`}
                  >
                    <i className={styles.iconMob}></i>
                  </Link>
                  <Link
                    onClick={() => handlePlatform(styles.platformButtonPc)}
                    className={`${styles.platformButtonButton} ${
                      platformButton === styles.platformButtonPc
                        ? styles.platformButtonActive
                        : ""
                    }`}
                  >
                    <i className={styles.iconPc}></i>
                  </Link>
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              className={`${styles.cardItem} ${styles.cardStatistic}`}
              initial="offscreen"
              whileInView="onscreen"
              variants={contentVariantsTop}
              viewport={{ once: true }}
            >
              <div className={styles.statistic}>
                <motion.div
                  className={styles.floating}
                  variants={contentVariantsRight}
                  custom={0.7}
                ></motion.div>
                <div className={styles.images}>
                  <img
                    alt="Блок статистики по выбранному периоду"
                    decoding="async"
                    fetchpriority="high"
                    src="/img/homepage/statistic/statistic.svg"
                    srcSet="/img/homepage/statistic/statistic.svg 1x, /img/homepage/statistic/statistic.svg 2x"
                  />
                </div>
              </div>
              <motion.div
                className={styles.cardText}
                variants={contentVariantsTop}
                custom={0.6}
              >
                <div className={styles.cardTitle}>
                  <h3>
                    Блок статистики <br />
                    по выбранному периоду
                  </h3>
                </div>
                <div className={styles.cardDescription}>
                  <p>
                    Показываем количество проданных товаров, процент продаж
                    розницы, и итоговую сумму.
                  </p>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
