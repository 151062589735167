import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../axios";

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }) => {
    try {
      const { data } = await axios.post("/account/login", {
        email,
        password,
      });

      localStorage.setItem("token", data.accessToken);
      return data;
    } catch (e) {
      throw e.response?.data?.message;
    }
  }
);

export const registration = createAsyncThunk(
  "auth/registration",
  async (params) => {
    try {
      const { data } = await axios.post("/account/register", params);

      localStorage.setItem("token", data.accessToken);
      return data;
    } catch (e) {
      throw e.response?.data?.message;
    }
  }
);

export const checkAuth = createAsyncThunk("auth/checkAuth", async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/account/refresh`,
      {
        withCredentials: true,
      }
    );

    localStorage.setItem("token", response.data.accessToken);
    return response.data;
  } catch (e) {
    localStorage.removeItem("token");
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    const response = await axios.post("/account/logout");

    localStorage.removeItem("token");
    return response.data;
  } catch (e) {
    throw e.response?.data?.message;
  }
});

export const password = createAsyncThunk("auth/password", async (email) => {
  try {
    await axios.post("/account/forgot-password", { email });
  } catch (e) {
    throw e.response?.data?.message;
  }
});

const initialState = {
  data: null,
  isActivated: false,
  roles: null,
  status: "loading",
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearErrorAfterTime: (state) => {
      state.error = null;
    },
    clearErrorOnNavigation: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.data = null;
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "loaded";
        state.isActivated = action.payload
          ? action.payload.user.isActivated
          : false;
        state.roles = action.payload ? action.payload.user.roles : null;
      })
      .addCase(login.rejected, (state, action) => {
        state.data = null;
        state.status = "error";
        state.error = action.error.message;
      })
      .addCase(registration.pending, (state) => {
        state.data = null;
        state.status = "loading";
      })
      .addCase(registration.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "loaded";
      })
      .addCase(registration.rejected, (state, action) => {
        state.data = null;
        state.status = "error";
        state.error = action.error.message;
      })
      .addCase(checkAuth.pending, (state) => {
        state.data = null;
        state.status = "loading";
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "loaded";
        // state.user = action.payload;
        state.isActivated = action.payload
          ? action.payload.user.isActivated
          : false;
        state.roles = action.payload ? action.payload.user.roles : null;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.data = null;
        state.status = "error";
        state.error = action.error.message;
      })
      .addCase(logout.pending, (state) => {
        state.data = null;
        state.status = "loading";
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.data = null;
        state.status = "loaded";
      })
      .addCase(logout.rejected, (state, action) => {
        state.data = null;
        state.status = "error";
        state.error = null;
      })
      .addCase(password.pending, (state) => {
        state.status = "loading";
      })
      .addCase(password.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "loaded";
      })
      .addCase(password.rejected, (state, action) => {
        state.data = null;
        state.status = "error";
        state.error = action.error.message;
      });
  },
});
export const { clearErrorAfterTime, clearErrorOnNavigation } =
  authSlice.actions;
export const selectError = (state) =>
  state.auth.error !== null ? String(state.auth.error) : false;
export const selectIsAuth = (state) => Boolean(state.auth.data);
export const selectIsAdmin = (state) => String(state.auth.roles);
export const selectIsActivated = (state) => Boolean(state.auth.isActivated);
export const authReducer = authSlice.reducer;
