import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = ({ ...props }) => (
  <ContentLoader
    speed={2}
    width={50}
    height={50}
    viewBox="0 0 50 50"
    backgroundColor="#101010"
    foregroundColor="#333333"
    {...props}
  >
    <rect x="0" y="0" rx="10" ry="10" width="50" height="50" />
  </ContentLoader>
);

export default Skeleton;
