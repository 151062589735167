import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = ({ ...props }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth > 1340 ? (
    <ContentLoader
      speed={2}
      width={653}
      height={107}
      viewBox="0 0 653 107"
      backgroundColor="#262626"
      foregroundColor="#333333"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="NaN" height="NaN" />
      <rect x="503" y="24" rx="3" ry="3" width="150" height="11" />
      <rect x="533" y="48" rx="3" ry="3" width="120" height="11" />
      <rect x="0" y="106" rx="5" ry="5" width="653" height="1" />
      <rect x="0" y="20" rx="3" ry="3" width="55" height="15" />
      <rect x="68" y="22" rx="2" ry="2" width="46" height="10" />
      <rect x="0" y="52" rx="8" ry="8" width="30" height="30" />
    </ContentLoader>
  ) : windowWidth > 1200 ? (
    <ContentLoader
      speed={2}
      width={315}
      height={107}
      viewBox="0 0 315 107"
      backgroundColor="#262626"
      foregroundColor="#333333"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="NaN" height="NaN" />
      <rect x="503" y="24" rx="3" ry="3" width="150" height="11" />
      <rect x="195" y="48" rx="3" ry="3" width="120" height="11" />
      <rect x="0" y="106" rx="5" ry="5" width="315" height="1" />
      <rect x="0" y="20" rx="3" ry="3" width="55" height="15" />
      <rect x="68" y="22" rx="2" ry="2" width="46" height="10" />
      <rect x="0" y="52" rx="8" ry="8" width="30" height="30" />
    </ContentLoader>
  ) : (
    <ContentLoader
      speed={2}
      width={315}
      height={107}
      viewBox="0 0 315 107"
      backgroundColor="#262626"
      foregroundColor="#333333"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="NaN" height="NaN" />
      <rect x="165" y="24" rx="3" ry="3" width="150" height="11" />
      <rect x="195" y="48" rx="3" ry="3" width="120" height="11" />
      <rect x="0" y="106" rx="5" ry="5" width="315" height="1" />
      <rect x="0" y="20" rx="3" ry="3" width="55" height="15" />
      <rect x="68" y="22" rx="2" ry="2" width="46" height="10" />
      <rect x="0" y="52" rx="8" ry="8" width="30" height="30" />
    </ContentLoader>
  );
};

export default Skeleton;
