import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import fetchUserInfo from "../../../../User/UserData";
import { getCategories } from "../../../../../redux/slises/Ecwid/ecwid";

import styles from "./Seller.module.sass";
import ModalCollection from "../../../../Modal/CollectionStatisctic/ModalCollection";

function SellerBlock({
  userRoles,
  userCollection,
  userFirstName,
  userLastName,
  userToken,
  onCollectionSelect,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeCollection, setActiveCollection] = React.useState("");
  const [isModalCollectionOpen, setModalCollectionOpen] = React.useState(false);
  const updatedCollectionToken = activeCollection
    ? activeCollection
    : userToken;

  React.useEffect(() => {
    dispatch(getCategories());
  }, []);

  React.useEffect(() => {
    setActiveCollection(updatedCollectionToken);
  }, [updatedCollectionToken]);

  const collection = useSelector((state) => state.ecwid.collection);

  const handleOpenModal = () => setModalCollectionOpen(true);
  const handleCloseModal = () => setModalCollectionOpen(false);

  const handleConfirm = async () => {
    setModalCollectionOpen(false);
  };

  const handleCancel = () => {
    setModalCollectionOpen(false);
  };

  const handleCollectionSelect = (artist) => {
    // Обновление состояния родителя выбранным артистом
    setActiveCollection(artist);
    onCollectionSelect(artist);
  };

  return (
    <>
      <div className={styles.sellerBlock}>
        {userRoles === "Artist" && userCollection.length > 1 && (
          <>
            <ModalCollection
              isOpen={isModalCollectionOpen}
              onRequestClose={handleCloseModal}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              onCollectionSelect={handleCollectionSelect}
            />
          </>
        )}
        {userRoles === "Admin" && (
          <>
            <ModalCollection
              isOpen={isModalCollectionOpen}
              onRequestClose={handleCloseModal}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              onCollectionSelect={handleCollectionSelect}
            />
          </>
        )}
        {userRoles === "Redactor" && (
          <>
            <ModalCollection
              isOpen={isModalCollectionOpen}
              onRequestClose={handleCloseModal}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              onCollectionSelect={handleCollectionSelect}
            />
          </>
        )}
        <div className={styles.sellerBlockInfo}>
          <div className={styles.sellerBlockAvatar}>
            {!userToken && (
              <>
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/no-avatar.png`}
                  height="90"
                  width="70"
                />
              </>
            )}
            {!collection || collection.length === 0 ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/no-avatar.png`}
                height="90"
                width="70"
              />
            ) : (
              collection.map((category) => {
                if (category.label === activeCollection) {
                  return (
                    <img
                      key={category.id}
                      src={
                        category.poster
                          ? category.poster
                          : `${process.env.REACT_APP_API_URL}/uploads/no-avatar.png`
                      }
                      height="90"
                      width="70"
                      alt={category.label}
                    />
                  );
                }
              })
            )}
          </div>
          <div className={styles.sellerBlockName}>
            <div className={styles.sellerBlockNameHeader}>
              <h2>
                {activeCollection || userToken
                  ? activeCollection || userToken
                  : userFirstName + " " + userLastName}
              </h2>
              <div
                className={styles.sellerBlockNameHeaderArrow}
                onClick={handleOpenModal}
              >
                <i className={styles.iconArrow}></i>
              </div>
            </div>
            <div className={styles.sellerBlockNameTitle}>
              {t("statisticSellerBlockTitle")}
            </div>
          </div>
        </div>
        <div className={styles.sellerBlockBackground}>
          {!collection || collection.length === 0 ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/uploads/no-avatar.png`}
              height="90"
              width="70"
            />
          ) : (
            collection.map((category) => {
              if (category.label === activeCollection) {
                return (
                  <img
                    key={category.id}
                    src={
                      category.poster
                        ? category.poster
                        : `${process.env.REACT_APP_API_URL}/uploads/no-avatar.png`
                    }
                    height="90"
                    width="70"
                    alt={category.label}
                  />
                );
              }
            })
          )}
        </div>
      </div>
    </>
  );
}

export default fetchUserInfo(SellerBlock);
